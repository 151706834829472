import path from '~/utils/fp/path';
import allPass from '~/utils/fp/all-pass';
import equals from '~/utils/fp/equals';
import orElse from '~/utils/fp/or-else';
import pick from '~/utils/fp/pick';
import map from '~/utils/fp/map';
import filter from '~/utils/fp/filter';
import propEqTrue from '~/utils/fp/prop-eq-true';
import isEmpty from '~/utils/object/is-empty';
import isNotEmpty from '~/utils/object/is-not-empty';
import {
  isAdult,
  isChild,
  isNotInfant,
  isTravelPartner,
  isAnonymPassenger,
} from '~/utils/booking/passenger';
import curry from '~/utils/fp/curry';
import length from '~/utils/fp/length';
import some from '~/utils/fp/some';
import either from '~/utils/fp/either';
import anyPass from '~/utils/fp/any-pass';
import propEq from '~/utils/fp/prop-eq';
import pathOr from '~/utils/fp/path-or';
import whereEq from '~/utils/fp/where-eq';
import complement from '~/utils/fp/complement';
import compose from '~/utils/fp/compose';
import propOr from '~/utils/fp/prop-or';
import { toKebabCase } from '~/utils/string';
import gt from '~/utils/fp/gt';
import lt from '~/utils/fp/lt';
import __ from '~/utils/fp/__';
import {
  ANCILLARY_AUTO_CHECK_IN,
  ANCILLARY_WIZZ_FLEX,
  ANCILLARY_SEATING_TOGETHER_GUARANTEE,
  BOOKING_HAS_BEEN_CANCELLED_DUE_TO_GOVERNMENT_REASONS,
  BOOKING_HAS_BEEN_AUTOMATIC_CANCELLED_DUE_TO_GOVERNMENT_REASONS,
  BOTH_FLIGHT_NAME,
  CHECK_IN_STATUS_CHECKED_IN,
  CUSTOMER_TYPE,
  FEE_NAME_PRIORITY_BOARDING,
  INFANT,
  ITINERARY_MODAL_ACORD_SERVICE,
  ITINERARY_MODAL_AIRPORT_TRANSFER,
  ITINERARY_MODAL_AIRPORT_PARKING,
  ITINERARY_MODAL_CHECK_IN,
  ITINERARY_MODAL_CONFIRM,
  ITINERARY_MODAL_CONTACT_UPDATE,
  ITINERARY_MODAL_FLIGHT_CHANGE_WARNING,
  ITINERARY_MODAL_MAKE_CHANGES,
  ITINERARY_MODAL_MODIFY_BOOKING_FLEXIBLE_TRAVEL_PARTNER,
  ITINERARY_MODAL_NAME_CHANGE,
  ITINERARY_MODAL_PASSENGER_INFORMATION,
  ITINERARY_MODAL_SERVICES_CONFIRM,
  ITINERARY_SECTION_ADD_BAGS,
  ITINERARY_SECTION_BOOKING_DETAILS,
  ITINERARY_SECTION_PASSENGER_DETAILS,
  ITINERARY_STATUS_DIVIDE_ERROR,
  OPERATION_ACCEPT_NEW_GCC,
  OPERATION_ADD_BAGGAGES,
  OPERATION_ADD_SERVICE,
  OPERATION_BLOCKED_BY_GCC,
  OPERATION_BUNDLE_UPSELL,
  OPERATION_CHANGE_MOBILE_PHONE,
  OPERATION_CHANGE_SEAT,
  OPERATION_CHECK_IN,
  OPERATION_DIVIDE_FLIGHT_CHANGE,
  OPERATION_FARE_LOCK_FINALIZE,
  OPERATION_FLIGHT_CHANGE,
  OPERATION_FLIGHT_COMPENSATION,
  OPERATION_GCC_CALL_CENTER_REFUND,
  OPERATION_GCC_REFUND,
  OPERATION_GROUP_BOOKING_NAME_CHANGE,
  OPERATION_LIST_INVOICES,
  OPERATION_NAME_CHANGE,
  OPERATION_SHOW_PRICE_DETAILS,
  OPERATION_UPDATE_MOBILE_PHONE_ON_PROFILE,
  OUTBOUND_FLIGHT,
  OUTBOUND_FLIGHT_NAME,
  PROP_FLIGHT_CHECK_IN_STATUS,
  RETURN_FLIGHT,
  RETURN_FLIGHT_NAME,
  STATUS_CANCEL_IN_PROGRESS,
  STATUS_CANCELED,
  STATUS_CONFIRMED,
  STATUS_FARE_LOCK_FINALIZE,
  STATUS_FARE_LOCKED,
  STATUS_FLIGHT_CHANGE_IN_PROGRESS,
  STATUS_FLOWN,
  STATUS_HOLD,
  STATUS_PENDING_PAYMENT,
  STATUS_WAIT,
  AUTO_CHECK_IN_LIMIT_IN_HOURS,
  PACKAGE_TRAVEL_UNLICENSED_CARRIERS,
  DIRECTION_OUTBOUND,
  DIRECTION_RETURN,
  AGENCY_BOOKING_HAS_BEEN_CANCELLED_DUE_TO_GOVERNMENT_REASONS,
  BOOKING_HAS_BEEN_UNDERPAID_NEVER_BALANCE_CANCELLED_DUE_TO_GOVERNMENT_REASONS,
  ITINERARY_STATUS_REACCOMODATE_ERROR,
  BOOKING_IS_UNDER_MODIFICATION,
  STATUS_WDC_CUT_IN_PROGRESS,
  STATUS_WDC_CUT_ERROR,
  CAR_RENTAL_PAYMENT_STATUS_SUCCESS,
} from '~/constants';
import propIsEmpty from '~/utils/fp/prop-is-empty';
import propIsNotEmpty from '~/utils/fp/prop-is-not-empty';
import isNotNil from '~/utils/object/is-not-nil';
import { getShortName } from '~/utils/resource';
// note: passengers/getters should come before summary/getters otherwise things
//  will break (eg profile page won't load cause missing getter)
// todo: resolve cyclic deps in passengers/getters
// todo: resolve cyclic deps in summary/getters
import { currentDateAndTime, formatDate, differenceInHours, isAfter } from '~/utils/date';
import { getAncillariesFromServices } from '~/utils/ancillary';
import intersection from '~/utils/fp/intersection';
import head from '~/utils/fp/head';
import hasIntersection from '~/utils/fp/has-intersection';
import { passengers as passengersPassengers } from '../passengers/getters';
import { exchangedAmountDue, amountDue } from '../summary/getters';
import * as userGetters from '../user/getters';
import * as resourcesGetters from '../resources/getters';
import {
  isGlobalOperationAvailable,
  isOutboundOperationAvailable,
  isReturnOperationAvailable,
  isFlightFlown,
  getStatus,
  isStatus,
  getFlight,
  showInfant,
  DEFAULT_DATE_TIME_FORMAT,
  initialSeatAssignmentsForFlight,
  paidSeatPricesForFlight,
  passengerCheckInStatusesForFlight,
  convertPassengersPassengersToRawPassengers,
} from './internal';

const itineraryProp = curry((prop, state) => path(prop, state.itinerary));

const itineraryPropEq = curry(
  (prop, value, state) => path(prop, state.itinerary) === value
);

const itineraryPropWithFallback = curry((fallbackValue, prop, state) =>
  pathOr(fallbackValue, `itinerary.${prop}`, state)
);

// global operations
export const canChangeName = isGlobalOperationAvailable(OPERATION_NAME_CHANGE);

export const canChangeNameOnGroupBooking = isGlobalOperationAvailable(
  OPERATION_GROUP_BOOKING_NAME_CHANGE
);

export const canListInvoices = isGlobalOperationAvailable(OPERATION_LIST_INVOICES);

export const canGetFlightCompensation = isGlobalOperationAvailable(
  OPERATION_FLIGHT_COMPENSATION
);

export const canFinalizeFareLock = isGlobalOperationAvailable(
  OPERATION_FARE_LOCK_FINALIZE
);

export const canShowPriceDetails = isGlobalOperationAvailable(
  OPERATION_SHOW_PRICE_DETAILS
);

export const canAcceptNewGcc = isGlobalOperationAvailable(OPERATION_ACCEPT_NEW_GCC);

export const canGccRefund = isGlobalOperationAvailable(OPERATION_GCC_REFUND);

export const isBlockedByGcc = isGlobalOperationAvailable(OPERATION_BLOCKED_BY_GCC);

export const canGccCallCenterRefund = isGlobalOperationAvailable(
  OPERATION_GCC_CALL_CENTER_REFUND
);

export const canChangeMobilePhone = isGlobalOperationAvailable(
  OPERATION_CHANGE_MOBILE_PHONE
);

export const canUpdateMobilePhoneOnProfile = isGlobalOperationAvailable(
  OPERATION_UPDATE_MOBILE_PHONE_ON_PROFILE
);

export const canQueryPostBookingUpsell = isGlobalOperationAvailable(
  OPERATION_BUNDLE_UPSELL
);

// outbound operations
export const canAddBaggageToOutboundFlight =
  isOutboundOperationAvailable(OPERATION_ADD_BAGGAGES);

export const canAddServiceToOutboundFlight =
  isOutboundOperationAvailable(OPERATION_ADD_SERVICE);

export const canChangeOutboundFlight = isOutboundOperationAvailable(
  OPERATION_FLIGHT_CHANGE
);

export const canChangeSeatOnOutboundFlight =
  isOutboundOperationAvailable(OPERATION_CHANGE_SEAT);

export const canCheckInOnOutboundFlight =
  isOutboundOperationAvailable(OPERATION_CHECK_IN);

export const canDoOutboundFlightChangeDivide = isOutboundOperationAvailable(
  OPERATION_DIVIDE_FLIGHT_CHANGE
);

// return operations
export const canAddBaggageToReturnFlight =
  isReturnOperationAvailable(OPERATION_ADD_BAGGAGES);

export const canAddServiceToReturnFlight =
  isReturnOperationAvailable(OPERATION_ADD_SERVICE);

export const canChangeReturnFlight = isReturnOperationAvailable(OPERATION_FLIGHT_CHANGE);

export const canChangeSeatOnReturnFlight =
  isReturnOperationAvailable(OPERATION_CHANGE_SEAT);

export const canCheckInOnReturnFlight = isReturnOperationAvailable(OPERATION_CHECK_IN);

export const canDoReturnFlightChangeDivide = isReturnOperationAvailable(
  OPERATION_DIVIDE_FLIGHT_CHANGE
);

export const canCheckInOutbound = (state) =>
  outboundCheckInPassengers(state).some(propIsEmpty('noCheckInReasons'));

export const canCheckInReturn = (state) =>
  returnCheckInPassengers(state).some(propIsEmpty('noCheckInReasons'));

export const canDoFlightChangeDivide = either(
  canDoOutboundFlightChangeDivide,
  canDoReturnFlightChangeDivide
);

export const status = (state) => getStatus(state);

export const isLoginRequiredToContinueToPayment = itineraryProp(
  'isLoginRequiredToContinueToPayment'
);

export const isSeatSelectionNeeded = itineraryProp('isSeatSelectionNeeded');

export const isConfirmed = isStatus(STATUS_CONFIRMED);

export const isPaymentPending = isStatus(STATUS_PENDING_PAYMENT);

export const isPaymentStatusWaiting = isStatus(STATUS_WAIT);

export const isHeld = isStatus(STATUS_HOLD);

export const isFareLocked = isStatus(STATUS_FARE_LOCKED);

export const isFlown = isStatus(STATUS_FLOWN);

export const isCanceled = isStatus(STATUS_CANCELED);

export const isCancellationPending = isStatus(STATUS_CANCEL_IN_PROGRESS);

export const isFlightChangePending = isStatus(STATUS_FLIGHT_CHANGE_IN_PROGRESS);

export const isWdcCutPending = isStatus(STATUS_WDC_CUT_IN_PROGRESS);

export const hasWdcCutError = isStatus(STATUS_WDC_CUT_ERROR);

export const isFareLockFinalized = isStatus(STATUS_FARE_LOCK_FINALIZE);

export const hasDivideError = isStatus(ITINERARY_STATUS_DIVIDE_ERROR);

export const hasReaccomodateError = isStatus(ITINERARY_STATUS_REACCOMODATE_ERROR);

export const isRoundTrip = (state) => getFlight(state, RETURN_FLIGHT_NAME) !== null;

export const isOutboundFlightFlown = isFlightFlown(OUTBOUND_FLIGHT_NAME);

export const isReturnFlightFlown = isFlightFlown(RETURN_FLIGHT_NAME);

export const isOutboundFlightDimmed = (state) =>
  isRoundTrip(state) && isOutboundFlightFlown(state) && !isReturnFlightFlown(state);

export const booking = itineraryPropWithFallback(null, 'booking');

export const upsoldBookings = itineraryProp('upsoldBookings');

export const outboundDepartureStationIata = itineraryProp(
  'booking.outboundFlight.departureStation'
);

export const outboundArrivalStationIata = itineraryProp(
  'booking.outboundFlight.arrivalStation'
);

export const isBookingAvailable = (state) => Boolean(booking(state));

export const isWdcBooking = (state) => state.itinerary?.booking?.isWdc;

export const isGoogleAnalyticsPostRequired = itineraryProp(
  'booking.isGoogleAnalyticsPostRequired'
);

export const airportTransfer = itineraryProp('booking.services.transfer');

export const isAirportTransferAvailable = (state) => Boolean(airportTransfer(state));

export const reservedCar = itineraryPropWithFallback(null, 'booking.reservedCar');

export const carTrawlerReservedCar = itineraryPropWithFallback(
  null,
  'booking.carTrawlerReservedCar'
);

export const isCarReservationSuccessful = (state) =>
  reservedCar(state)?.paymentStatus === CAR_RENTAL_PAYMENT_STATUS_SUCCESS;

export const hasRentalcarsCarRental = (state) => isNotNil(reservedCar(state));

export const isCarTrawlerReservationSuccessful = (state) =>
  carTrawlerReservedCar(state)?.paymentStatus === CAR_RENTAL_PAYMENT_STATUS_SUCCESS;

export const hasCarTrawlerCarRental = (state) => isNotNil(carTrawlerReservedCar(state));

export const outboundFlight = itineraryProp('booking.outboundFlight');

export const returnFlight = itineraryProp('booking.returnFlight');

export const returnFlightNumber = itineraryProp('booking.returnFlight.flightNumber');

export const outboundFlightNumber = itineraryProp('booking.outboundFlight.flightNumber');

export const hasOutboundFlight = compose(isNotEmpty, outboundFlight);

export const hasReturnFlight = compose(isNotEmpty, returnFlight);

export const preferredInvoiceTab = (state) => state.itinerary?.preferredInvoiceTab;

export const availableOperations = (state) =>
  state.itinerary?.booking?.availableOperations;

export const bookingHasPaymentIssue = itineraryProp('booking.bookingHasPaymentIssue');

export const isSuccessfulBookingLoaded = itineraryProp('isSuccessfulBookingLoaded');

export const flights = (state) => ({
  [OUTBOUND_FLIGHT_NAME]: outboundFlight(state),
  [RETURN_FLIGHT_NAME]: returnFlight(state),
});

export const fareLockDates = (state) => {
  if (!isFareLocked(state)) return {};

  const booking = state.itinerary.booking;

  return {
    holdUntil: formatDate(DEFAULT_DATE_TIME_FORMAT, booking.holdUntil),
    timeUntil: Math.abs(differenceInHours(currentDateAndTime(), booking.holdUntil)),
  };
};

export const airportTransferAtDepartureStation = itineraryPropWithFallback(
  null,
  'booking.airportTransferAtDepartureStation'
);

export const airportTransferAtArrivalStation = itineraryPropWithFallback(
  null,
  'booking.airportTransferAtArrivalStation'
);

export const lastNameChangeDate = (state) => state.itinerary.booking.lastNameChangeDate;

export const formattedLastNameChangeDate = (state) =>
  formatDate(DEFAULT_DATE_TIME_FORMAT, state.itinerary.booking.lastNameChangeDate);

export const isFlightCompensationErrorVisible = itineraryPropEq(
  'booking.flightCompensationState',
  'error'
);

export const payments = itineraryPropWithFallback([], 'booking.payments');

export const availableAncillaryServices = itineraryPropWithFallback(
  [],
  'booking.availableAncillaryServicesForItinerary'
);

export const selectedServices = (state) =>
  availableAncillaryServices(state).filter(
    anyPass(
      propEq('selectedForFlightType', OUTBOUND_FLIGHT_NAME),
      propEq('selectedForFlightType', RETURN_FLIGHT_NAME),
      propEq('selectedForFlightType', BOTH_FLIGHT_NAME)
    )
  );

export const hasSelectedServices = (state) => selectedServices(state).length > 0;

export const outboundPurchasedServices = (state) =>
  availableAncillaryServices(state).filter(
    either(
      propEq('selectedForFlightType', OUTBOUND_FLIGHT_NAME),
      propEq('selectedForFlightType', BOTH_FLIGHT_NAME)
    )
  );

export const returnPurchasedServices = (state) =>
  availableAncillaryServices(state).filter(
    either(
      propEq('selectedForFlightType', RETURN_FLIGHT_NAME),
      propEq('selectedForFlightType', BOTH_FLIGHT_NAME)
    )
  );

/**
 * @type {(direction: Direction) => (state: State) => Object<string, any>[]}
 */
const passengerAncillariesByDirection = curry((direction, state) => {
  return passengers(state).reduce((acc, passenger) => {
    const _services = pathOr({}, `${direction}Flight.services`, passenger);
    Object.entries(_services).forEach(([key, _service]) => {
      acc.push({
        ..._service,
        type: key,
      });
    });
    return acc;
  }, []);
});

export const outboundPassengerAncillaries =
  passengerAncillariesByDirection(OUTBOUND_FLIGHT_NAME);

export const returnPassengerAncillaries =
  passengerAncillariesByDirection(RETURN_FLIGHT_NAME);

export const somePassengerHasPriorityBoardingOnEitherFlight = (state) =>
  [...outboundPassengerAncillaries(state), ...returnPassengerAncillaries(state)].some(
    propEq('type', FEE_NAME_PRIORITY_BOARDING)
  );

export const hasOutboundPurchasedServices = (state) =>
  isNotEmpty(outboundPurchasedServices(state));

export const hasReturnPurchasedServices = (state) =>
  isNotEmpty(returnPurchasedServices(state));

export const hasWizzFlex = (state) =>
  selectedServices(state).some(propEq('type', ANCILLARY_WIZZ_FLEX));

export const hasSeatingTogetherGuarantee = (state) =>
  selectedServices(state).some(propEq('type', ANCILLARY_SEATING_TOGETHER_GUARANTEE));

export const hasAutoCheckIn = compose(
  some(propEq('type', ANCILLARY_AUTO_CHECK_IN)),
  selectedServices
);

export const sixtCarRental = itineraryProp('booking.sixtCar');

export const hasSixtCarRental = (state) => isNotNil(sixtCarRental(state));

export const bankTransfer = itineraryPropWithFallback(
  null,
  'booking.bankTransferDetails'
);

export const isBankTransferAvailable = (state) => Boolean(bankTransfer(state));

export const bankTransferAmount = itineraryProp('booking.bankTransferDetails.amount');

export const bankTransferAmountAmount = itineraryPropWithFallback(
  0,
  'booking.bankTransferDetails.amount.amount'
);

export const hasPendingBankTransferPayment = (state) =>
  isBankTransferAvailable(state)
    ? isHeld(state) && bankTransferAmountAmount(state) > 0
    : false;

export const isContactAvailable = (state) => Boolean(contact(state));

export const contact = itineraryProp('booking.contact');

export const contactErrors = itineraryProp('contact.errors');

export const hasContactErrors = compose(isNotEmpty, contactErrors);

export const isContactLoading = compose(
  gt(__, 0),
  itineraryProp('contact.loadingCounter')
);

export const isContactUpdated = itineraryProp('contact.isUpdated');

export const numberOfPassengers = (state) => passengers(state).length;

export const passengers = itineraryPropWithFallback([], 'booking.passengers');

export const checkInSelectedPassengers = itineraryPropWithFallback(
  [],
  'checkInData.passengers'
);

export const checkInSelectedPassengersNumber = (state) =>
  checkInSelectedPassengers(state).reduce((acc, passenger) => {
    acc.push(passenger.passengerNumber);
    return acc;
  }, []);

export const checkInSelectedPassengersWithoutInfants = (state) =>
  checkInSelectedPassengers(state).filter(isNotInfant);

export const passengersWithoutInfants = compose(filter(isNotInfant), passengers);

export const numberOfPassengersWithoutInfants = (state) =>
  passengersWithoutInfants(state).length;

export const passengersWithInfants = (state) =>
  passengers(state)
    // adults with infant property
    .filter((passenger) => isAdult(passenger) && passenger.infant !== null)
    // do not show the passenger if the infant has not checked in for neither return nor outbound
    .filter(
      (passenger) =>
        showInfant(state, OUTBOUND_FLIGHT_NAME, passenger) ||
        showInfant(state, RETURN_FLIGHT_NAME, passenger)
    )
    .map((pwi) => {
      pwi.isHiddenOnOutbound = !showInfant(state, OUTBOUND_FLIGHT_NAME, pwi);
      pwi.isHiddenOnReturn = !showInfant(state, RETURN_FLIGHT_NAME, pwi);
      pwi.infant.passengerNumber = `I${pwi.passengerNumber}`;
      return pwi;
    });

export const numberOfAdults = (state) => passengers(state).filter(isAdult).length;

export const numberOfChildren = (state) => passengers(state).filter(isChild).length;

export const numberOfInfants = (state) =>
  passengers(state)
    .filter(isAdult)
    .filter((passenger) => isNotNil(passenger.infant)).length;

export const hasPassengers = (state) => passengers(state).length > 0;

export const checkInPassengers = itineraryProp('checkInPassengers');

export const outboundCheckInPassengers = itineraryPropWithFallback(
  [],
  'checkInPassengers.outbound'
);

export const returnCheckInPassengers = itineraryPropWithFallback(
  [],
  'checkInPassengers.return'
);

export const outboundCheckInStartDate = (state) =>
  state.itinerary?.checkInPassengers?.outbound?.[0]?.checkInStartDate;

export const returnCheckInStartDate = (state) =>
  state.itinerary?.checkInPassengers?.return?.[0]?.checkInStartDate;

export const checkInFlowPassengers = (state) => {
  const pass = outboundCheckInPassengers(state).map((passenger) => {
    const {
      firstName,
      lastName,
      gender,
      infant,
      seatCode,
      pdfBoardingCardEnabled,
      htmlBoardingCardEnabled,
      flightCheckinStatus,
    } = passenger;

    return {
      firstName,
      lastName,
      gender,
      infant,
      seatCode,
      flightCheckinStatus,
      pdfBoardingCardEnabled,
      htmlBoardingCardEnabled,
    };
  });

  return pass;
};

export const hasCheckInPassengers = (state) =>
  outboundCheckInPassengers(state).length > 0 ||
  (isRoundTrip(state) && returnCheckInPassengers(state).length > 0);

export const selectedCheckInPassenger = itineraryProp('selectedCheckInPassenger');

/**
 * @type {(state: {}) => {}}
 */
export const totalAmountDue = itineraryProp('booking.totalAmountDue');

/**
 * @type {(state: {}) => number}
 */
export const totalAmountDueAmount = compose(
  propOr(0, 'amount'),
  orElse({}),
  totalAmountDue
);

/**
 * @type {(state: {}) => string}
 */
export const totalAmountDueCurrency = compose(
  propOr('', 'currencyCode'),
  orElse({}),
  totalAmountDue
);

/**
 * @type {(state: {}) => boolean}
 */
export const hasTotalAmountDue = compose(gt(__, 0), totalAmountDueAmount);

/**
 * @type {(state: {}) => boolean}
 */
export const hasNegativeTotalAmountDue = compose(lt(__, 0), totalAmountDueAmount);

/**
 * @type {(state: {}) => {}}
 */
export const totalPaidAmount = itineraryProp('booking.totalPaidAmount');

/**
 * @type {(state: {}) => number}
 */
export const totalPaidAmountAmount = compose(
  propOr(0, 'amount'),
  orElse({}),
  totalPaidAmount
);

export const recentlyPaidAmount = itineraryProp('recentlyPaidAmount');

export const documentRulesByCountries = itineraryProp(
  'checkInData.documentRulesByCountries'
);

/**
 * @type {(state: {}) => string}
 */
export const totalPaidAmountCurrency = compose(
  propOr('', 'currencyCode'),
  orElse({}),
  totalPaidAmount
);

export const totalAmount = (state) => {
  const _totalPaidAmountAmount = totalPaidAmountAmount(state);
  const _totalAmountDueAmount = totalAmountDueAmount(state);

  if (isBookingAvailable(state) && hasTotalAmountDue(state)) {
    return _totalPaidAmountAmount + _totalAmountDueAmount;
  }

  if (isBookingAvailable(state) && (isConfirmed(state) || isFlown(state))) {
    return _totalPaidAmountAmount;
  }

  return _totalAmountDueAmount;
};

export const invoices = itineraryProp('invoices');

export const currencyCode = (state) => {
  if (isBookingAvailable(state) && (isConfirmed(state) || isFlown(state))) {
    return totalPaidAmountCurrency(state);
  }

  return totalAmountDueCurrency(state);
};

export const pnr = itineraryProp('booking.pnr');

export const lastName = itineraryProp('booking.lastName');

export const bookingCurrencyCode = itineraryProp('booking.bookingCurrencyCode');

export const earliestCheckinWithoutOldSeatVersion = itineraryProp(
  'booking.bookingHasEarliestCheckinWithoutSeatOldVersionAllowedComment'
);

export const departureStationCurrencyCode = itineraryProp(
  'booking.departureStationCurrencyCode'
);

export const arrivalStationCurrencyCode = itineraryProp(
  'booking.arrivalStationCurrencyCode'
);

export const isLoading = (state) => state.itinerary?.loadingCounter > 0;

export const errors = itineraryProp('errors');

export const hasServerError = itineraryProp('hasServerError');

export const hasErrors = (state) => isNotEmpty(errors(state));

export const isGroupBooking = itineraryProp(`booking.isGroupBooking`);

export const outboundServices = itineraryProp(`booking.services.${OUTBOUND_FLIGHT_NAME}`);

export const returnServices = itineraryProp(`booking.services.${RETURN_FLIGHT_NAME}`);

export const hasOutboundServices = (state) => isNotEmpty(outboundServices(state));

export const hasReturnServices = (state) => isNotEmpty(returnServices(state));

export const seatAssignmentResults = (state) => ({
  [OUTBOUND_FLIGHT]: outboundSeatAssignmentResult(state),
  [RETURN_FLIGHT]: returnSeatAssignmentResult(state),
});

export const outboundSeatAssignmentResult = (state) => {
  let direction = OUTBOUND_FLIGHT;

  if (isRoundTrip(state) && isOutboundFlightFlown(state)) {
    direction = RETURN_FLIGHT;
  }

  return (
    state.itinerary?.bookingPassengers?.[0]?.[direction]?.seatAssignmentResult || null
  );
};

export const returnSeatAssignmentResult = (state) =>
  isRoundTrip(state) && isOutboundFlightFlown(state)
    ? null
    : state.itinerary?.bookingPassengers?.[0]?.[RETURN_FLIGHT]?.seatAssignmentResult ||
      null;

export const initialSeatAssignments = (state) => ({
  [OUTBOUND_FLIGHT]: outboundInitialSeatAssignments(state),
  [RETURN_FLIGHT]: returnInitialSeatAssignments(state),
});

export const outboundInitialSeatAssignments = (state) => {
  let direction = OUTBOUND_FLIGHT;

  if (isRoundTrip(state) && isOutboundFlightFlown(state)) {
    direction = RETURN_FLIGHT;
  }

  return initialSeatAssignmentsForFlight(direction, state);
};

export const returnInitialSeatAssignments = (state) =>
  isRoundTrip(state) && isOutboundFlightFlown(state)
    ? null
    : initialSeatAssignmentsForFlight(RETURN_FLIGHT, state);

export const paidSeatPrices = (state) => ({
  [OUTBOUND_FLIGHT]: outboundPaidSeatPricesFrom(state),
  [RETURN_FLIGHT]: returnPaidSeatPricesFrom(state),
});

export const outboundPaidSeatPricesFrom = (state) => {
  let direction = OUTBOUND_FLIGHT;

  if (isRoundTrip(state) && isOutboundFlightFlown(state)) {
    direction = RETURN_FLIGHT;
  }

  return paidSeatPricesForFlight(direction, state);
};

export const returnPaidSeatPricesFrom = (state) =>
  isRoundTrip(state) && isOutboundFlightFlown(state)
    ? null
    : paidSeatPricesForFlight(RETURN_FLIGHT, state);

export const checkInStatuses = (state) => ({
  [OUTBOUND_FLIGHT]: outboundCheckInStatuses(state),
  [RETURN_FLIGHT]: returnCheckInStatuses(state),
});

export const outboundCheckInStatuses = (state) => {
  let direction = OUTBOUND_FLIGHT_NAME;

  if (isRoundTrip(state) && isOutboundFlightFlown(state)) {
    direction = RETURN_FLIGHT_NAME;
  }

  return passengerCheckInStatusesForFlight(direction, state);
};

export const returnCheckInStatuses = (state) =>
  isRoundTrip(state) && isOutboundFlightFlown(state)
    ? {}
    : passengerCheckInStatusesForFlight(RETURN_FLIGHT_NAME, state);

export const hasBookingPassengers = (state) => isNotEmpty(passengersPassengers(state));

export const rawPassengers = (state) =>
  convertPassengersPassengersToRawPassengers(
    passengers(state),
    passengersPassengers(state)
  );

export const rawPassengersWithoutInfants = (state) =>
  rawPassengers(state).filter(complement(propEq('type', INFANT)));

export const isSpecialCabinBaggageAppliedForOutbound = itineraryProp(
  'booking.isSpecialCabinBaggageAppliedForOutBound'
);

export const isSpecialCabinBaggageAppliedForReturn = itineraryProp(
  'booking.isSpecialCabinBaggageAppliedForReturn'
);

// from the rabbit hole
export const gccAcceptanceCarrierCode = itineraryPropWithFallback(
  '',
  'booking.gccAcceptanceInformation.acceptanceCarrierCode'
);

// from the rabbit hole
export const shouldAcceptCarrierChange = itineraryPropWithFallback(
  false,
  'booking.gccAcceptanceInformation.hasToAcceptGCC'
);

export const isBoardingCardReprintNeededForOutbound = (state) =>
  passengers(state).some(whereEq({ 'outboundFlight.boardingCardReprintNeeded': true }));

export const isBoardingCardReprintNeededForReturn = (state) =>
  passengers(state).some(whereEq({ 'returnFlight.boardingCardReprintNeeded': true }));

export const isAddServiceVisitedOnce = (state) =>
  state.itinerary?.isAddServiceVisitedOnce || false;

export const activeModal = itineraryProp('activeModal');

export const isEveryModalHidden = itineraryPropEq('activeModal', null);

export const isPassengerInformationModalVisible = itineraryPropEq(
  'activeModal',
  ITINERARY_MODAL_PASSENGER_INFORMATION
);

export const isCheckInModalVisible = itineraryPropEq(
  'activeModal',
  ITINERARY_MODAL_CHECK_IN
);

export const isConfirmModalAtCheckInServicesVisible = itineraryPropEq(
  'activeModal',
  ITINERARY_MODAL_CONFIRM
);

export const isConfirmModalAtServicesVisible = itineraryPropEq(
  'activeModal',
  ITINERARY_MODAL_SERVICES_CONFIRM
);

export const isMakeChangesModalVisible = itineraryPropEq(
  'activeModal',
  ITINERARY_MODAL_MAKE_CHANGES
);

export const isContactUpdateModalVisible = itineraryPropEq(
  'activeModal',
  ITINERARY_MODAL_CONTACT_UPDATE
);

export const isNameChangeModalVisible = itineraryPropEq(
  'activeModal',
  ITINERARY_MODAL_NAME_CHANGE
);

export const isFlightChangeWarningModalVisible = itineraryPropEq(
  'activeModal',
  ITINERARY_MODAL_FLIGHT_CHANGE_WARNING
);

export const isModifyBookingFlexibleTravelPartnersModalVisible = itineraryPropEq(
  'activeModal',
  ITINERARY_MODAL_MODIFY_BOOKING_FLEXIBLE_TRAVEL_PARTNER
);
export const isAirportTransferModalVisible = itineraryPropEq(
  'activeModal',
  ITINERARY_MODAL_AIRPORT_TRANSFER
);

export const isAirportParkingModalVisible = itineraryPropEq(
  'activeModal',
  ITINERARY_MODAL_AIRPORT_PARKING
);

export const isAcordServiceModalVisible = itineraryPropEq(
  'activeModal',
  ITINERARY_MODAL_ACORD_SERVICE
);

export const isSeatSelectionModalVisible = itineraryProp('isSeatSelectionModalVisible');

export const isUpgradeBundleModalVisible = itineraryProp('isUpgradeBundleModalVisible');

export const isOwnerOfBooking = itineraryPropWithFallback(false, 'booking.isOwner');

const customerType = itineraryProp('booking.contact.customerType');

export const isAgencyCustomer = compose(equals(CUSTOMER_TYPE.AGENCY), customerType);

export const canChangeFlight = either(canChangeOutboundFlight, canChangeReturnFlight);

export const canCancelFlightWithoutFlex = itineraryProp(
  'booking.isCancellationAvailable'
);

export const canCancelFlightWithFlex = itineraryProp(
  'booking.isFlightCancellationWithFlexAvailable'
);

export const canCancelFlight = either(
  canCancelFlightWithoutFlex,
  canCancelFlightWithFlex
);

export const canMakeChangesOnOutboundFlight = either(
  canChangeName,
  canChangeOutboundFlight,
  canCancelFlight
);

export const canMakeChangesOnReturnFlight = either(
  canChangeName,
  canChangeReturnFlight,
  canCancelFlight
);

export const canAddService = either(
  canAddServiceToOutboundFlight,
  canAddServiceToReturnFlight
);

export const canAddBaggage = either(
  canAddBaggageToOutboundFlight,
  canAddBaggageToReturnFlight
);

export const canChangeSeat = either(
  canChangeSeatOnOutboundFlight,
  canChangeSeatOnReturnFlight
);

export const isBookingDetailsSectionVisible = itineraryPropEq(
  'activeSection',
  ITINERARY_SECTION_BOOKING_DETAILS
);

export const isAddBagsSectionVisible = itineraryPropEq(
  'activeSection',
  ITINERARY_SECTION_ADD_BAGS
);

export const isPassengerDetailsSectionVisible = itineraryPropEq(
  'activeSection',
  ITINERARY_SECTION_PASSENGER_DETAILS
);

export const isStandardFlow = (state) =>
  (isEveryModalHidden(state) || isMakeChangesModalVisible(state)) &&
  !isSeatSelectionModalVisible(state) &&
  isBookingDetailsSectionVisible(state);

export const isAddBagsFlow = (state) =>
  isEveryModalHidden(state) && isAddBagsSectionVisible(state);

// todo: isAddBagsFlowFlag vs isAddBagsFlow problem, both are used
export const isAddBagsFlowFlag = itineraryPropWithFallback(false, 'isAddBagsFlow');

export const isSeatChangeFlow = (state) =>
  isSeatSelectionModalVisible(state) && isBookingDetailsSectionVisible(state);

export const isNameChangeFlow = (state) =>
  isEveryModalHidden(state) && isPassengerDetailsSectionVisible(state);

export const selectedPassenger = itineraryProp('selectedPassenger');

export const checkInInformation = (state) => ({
  direction: itineraryPropWithFallback(OUTBOUND_FLIGHT_NAME, 'currentType', state),
  flight: itineraryProp('currentFlight', state),
  passenger: itineraryProp('selectedPassenger', state),
  checkInPassenger: itineraryProp('selectedCheckInPassenger', state),
});

export const cartAmountDue = (state) =>
  isCurrencyChanged(state) ? exchangedAmountDue(state) : amountDue(state);

export const isCurrencyChanged = (state) =>
  currentCurrency(state) !== bookingCurrencyCode(state);

export const currentCurrency = itineraryProp('currentCurrency');

export const privilegePassHolders = (state) =>
  passengersPassengers(state).filter(propEq('privilegePassActivated', true));

export const isCheckInUpsellEnabled = (state) =>
  hasBookingPassengers(state) &&
  isBookingAvailable(state) &&
  (canAddService(state) || canAddBaggage(state));

export const travelPartners = (state) => passengers(state).filter(isTravelPartner);
export const anonymPassengers = (state) =>
  passengers(state).filter((pax) => isAnonymPassenger(pax) && !isTravelPartner(pax));

export const hasTravelPartners = (state) => gt(travelPartners(state).length, 0);
export const hasAnonymPassengers = (state) => gt(anonymPassengers(state).length, 0);

export const isNewVersionSupported = itineraryPropWithFallback(
  false,
  'booking.isNewVersionSupported'
);

export const isStaffBooking = itineraryPropWithFallback(false, 'booking.isStaffBooking');

export const bookingComCreditBackPercentage = itineraryPropWithFallback(
  0,
  'booking.bookingComCreditBackPercentage'
);

export const carRentalCreditBackPercentage = itineraryPropWithFallback(
  0,
  'booking.carRentalCreditBackPercentage'
);

export const outboundCarrierItinerary = itineraryPropWithFallback(
  null,
  'booking.outboundFlight.carrier'
);

export const returnCarrierItinerary = itineraryPropWithFallback(
  null,
  'booking.returnFlight.carrier'
);

export const isDomesticItinerary = itineraryPropWithFallback(false, 'booking.isDomestic');

export const isTravelDocumentRequired = (state) =>
  state.itinerary.checkInData.passengers.some(propEqTrue('isTravelDocumentRequired'));

export const isTravelDocumentRequiredOnOldItinerary = itineraryProp(
  'selectedCheckInPassenger.isTravelDocumentRequired'
);

export const isContactDataRequired = (state) =>
  (checkInSelectedPassengers(state).some(propEq('passengerNumber', 0)) ||
    shouldValidateCheckInPassengers(state)) &&
  !isTravelDocumentRequired(state);

export const outboundDepartureDate = itineraryProp(
  'booking.outboundFlight.departureDate'
);

export const outboundArrivalDate = itineraryProp('booking.outboundFlight.arrivalDate');

export const returnDepartureDate = itineraryProp('booking.returnFlight.departureDate');

export const returnArrivalDate = itineraryProp('booking.returnFlight.arrivalDate');

export const isAutoCheckInAvailableBeforeCheckIn = (state) =>
  differenceInHours(outboundDepartureDate(state), currentDateAndTime()) >=
  AUTO_CHECK_IN_LIMIT_IN_HOURS;

export const isOutboundCheckInWithinRange = (state) =>
  isNotEmpty(outboundCheckInPassengers(state)) &&
  outboundCheckInPassengers(state).every((passenger) =>
    isAfter(currentDateAndTime(), passenger.checkinStartDateOutboundFlight)
  );

export const isReturnCheckInWithinRange = (state) =>
  isNotEmpty(returnCheckInPassengers(state)) &&
  returnCheckInPassengers(state).every((passenger) =>
    isAfter(currentDateAndTime(), passenger.checkinStartDateReturnFlight)
  );

export const hasRecentlyPurchasedItems = (state) => {
  const recentlyPurchasedItems = itineraryProp(
    'checkInData.recentlyPurchasedItems',
    state
  );
  return (
    recentlyPurchasedItems &&
    (!isEmpty(recentlyPurchasedItems.ancillaries) ||
      !isEmpty(recentlyPurchasedItems.services))
  );
};

export const recentlyPurchasedItems = itineraryProp('checkInData.recentlyPurchasedItems');

export const isEmptyPaymentOccurred = itineraryProp('checkInData.isEmptyPaymentOccurred');

export const priceDetails = itineraryProp('priceDetails');

export const priceDetailsErrors = itineraryProp('priceDetailsErrors');

export const isMobilePhoneSaveToProfileChecked = itineraryProp(
  'booking.isMobilePhoneSaveToProfileChecked'
);

export const hasCheckInPassengerWithUpdateProfilePhoneNumber = compose(
  some(propEqTrue('saveMobilePhoneToProfile')),
  checkInSelectedPassengers
);

export const bookingDate = itineraryProp('booking.bookingDate');

export const hasBannedPassenger = (state) =>
  isNotEmpty(itineraryProp('checkInData.bannedPassengers', state));

export const bannedPassengerWarningSeenBy = (state) =>
  itineraryProp('checkInData.bannedPassengerWarningSeenBy', state) ===
  itineraryProp('checkInData.bannedPassengers', state).length;

export const shouldShowCheckInDeniedModal = (state) => {
  return (
    hasBannedPassenger(state) &&
    itineraryProp('checkInData.bannedPassengerWarningSeenBy', state) <
      itineraryProp('checkInData.bannedPassengers', state).length
  );
};

const bannedPassengersList = (state) =>
  Array.from(new Set(itineraryProp('checkInData.bannedPassengers', state)));

// Banned passenger after the passengerValidation endpoint
export const bannedPassengers = (state) =>
  compose(
    map(pick(['gender', 'firstName', 'lastName', 'passengerNumber'])),
    // todo
    filter((passenger) =>
      bannedPassengersList(state).includes(passenger.passengerNumber)
    ),
    checkInSelectedPassengers
  )(state);

export const canContinueCheckIn = (state) =>
  gt(length(checkInSelectedPassengers(state)), length(bannedPassengers(state)));

export const shouldValidateCheckInPassengers = compose(
  some(propEqTrue('needDateOfBirth')),
  checkInSelectedPassengers
);

export const hasCustomerRated = itineraryProp('booking.availableFeedbackStages');

export const numberOfPassengersWithPrivilegePass = (state) =>
  privilegePassHolders(state).length;

export const isUnderPaid = (state) => isPaymentPending(state) && hasTotalAmountDue(state);

export const isOverPaid = (state) =>
  isConfirmed(state) && hasNegativeTotalAmountDue(state);

export const isNoticeForAnonymUserVisible = (state) => isPaymentPending(state);

const isNotOwnerAndHeld = (state) => isHeld(state) && !isOwnerOfBooking(state);

/**
 * @type {(state: State) => boolean}
 */
export const isPaymentRelatedNoticeVisible = allPass(
  allPass(complement(isCancellationPending), complement(isFlightChangePending)),
  anyPass(
    isUnderPaid,
    isOverPaid,
    isNoticeForAnonymUserVisible,
    isPaymentStatusWaiting,
    isNotOwnerAndHeld,
    bookingHasPaymentIssue
  )
);

export const isBankTransferNoticeVisible = (state) =>
  isHeld(state) && bankTransfer(state);

export const trackingNumber = itineraryProp('booking.trackingNumber');

/**
 * @type {(state: {}) => {}}
 */
export const holdUntil = itineraryProp('booking.holdUntil');

export const fareLockHoldTimeInHours = itineraryProp('booking.fareLockHoldTimeInHours');

export const isFlightChangeWarningModalShown = itineraryProp(
  'isFlightChangeWarningModalShown'
);

export const isMobilePhoneUpdateNeeded = itineraryProp('booking.needToAddMobilePhone');

export const isTravelDocumentNeeded = itineraryProp('booking.needToAddTravelDoc');

export const isNonSchengenNotificationVisible = itineraryProp(
  'booking.displayNonSchengenNotification'
);

export const lostServices = itineraryPropWithFallback([], 'booking.lostServices');

export const hasLostServices = compose(isNotEmpty, lostServices);

export const lostServiceNotifications = (state) => {
  return lostServices(state).map((service) => {
    const serviceName = toKebabCase(service.service);
    const stations = service.stations
      .map((station) => getShortName(resourcesGetters.stationsWithFakes(state), station))
      .join(',');
    return {
      titleKey: `itinerary-notice-${serviceName}-lost-title`,
      contentKey: `itinerary-notice-${serviceName}-lost-content`,
      contentData: [stations, service.emailAddress],
    };
  });
};

const hasOutboundPassengers = (state) =>
  !isOutboundFlightFlown(state) &&
  outboundCheckInPassengers(state).some(
    propEq(PROP_FLIGHT_CHECK_IN_STATUS, CHECK_IN_STATUS_CHECKED_IN)
  );

const hasReturnPassengers = (state) =>
  isRoundTrip(state) &&
  !isReturnFlightFlown(state) &&
  returnCheckInPassengers(state).some(
    propEq(PROP_FLIGHT_CHECK_IN_STATUS, CHECK_IN_STATUS_CHECKED_IN)
  );

export const isAnyPassengerCheckedIn = (state) => {
  if (!hasCheckInPassengers(state) || isFlown(state)) return false;
  return hasOutboundPassengers(state) || hasReturnPassengers(state);
};

export const divideKind = itineraryProp('divideKind');

export const isSuccessfulDivide = compose(isNotEmpty, divideKind);

export const isReducedMobilityInstructionsVisible = (state) => {
  if (!hasPassengers(state)) return false;

  return passengers(state).some(
    (passenger) =>
      passenger.prm &&
      ((passenger.outboundFlight && !passenger.outboundFlight.seatUnitDesignator) ||
        (passenger.returnFlight && !passenger.returnFlight.seatUnitDesignator))
  );
};

export const specialItineraryError = compose(
  orElse(null),
  head,
  intersection([
    BOOKING_HAS_BEEN_CANCELLED_DUE_TO_GOVERNMENT_REASONS,
    AGENCY_BOOKING_HAS_BEEN_CANCELLED_DUE_TO_GOVERNMENT_REASONS,
    BOOKING_HAS_BEEN_AUTOMATIC_CANCELLED_DUE_TO_GOVERNMENT_REASONS,
    BOOKING_IS_UNDER_MODIFICATION,
    BOOKING_HAS_BEEN_UNDERPAID_NEVER_BALANCE_CANCELLED_DUE_TO_GOVERNMENT_REASONS,
  ]),
  errors
);

export const specialItineraryErrorHasWarningFlag = compose(
  hasIntersection([BOOKING_IS_UNDER_MODIFICATION]),
  errors
);

export const flightCompensation = itineraryProp('booking.flightCompensation');

export const currentType = itineraryProp('currentType');

export const currentFlight = itineraryProp('currentFlight');

export const successfulBookingFlights = (state) => [
  {
    ...outboundFlight(state),
    direction: DIRECTION_OUTBOUND,
  },
  ...(isRoundTrip(state)
    ? [
        {
          ...returnFlight(state),
          direction: DIRECTION_RETURN,
        },
      ]
    : []),
];

export const successfulBookingPassengers = compose(
  map((passenger) => ({
    ...passenger,
    hasInfant: passenger.infant,
    outboundFlightAncillaries: getAncillariesFromServices(
      passenger.outboundFlight?.services ?? {},
      passenger.outboundFlight?.seatUnitDesignator
    ),
    returnFlightAncillaries: getAncillariesFromServices(
      passenger.returnFlight?.services ?? {},
      passenger.returnFlight?.seatUnitDesignator
    ),
  })),
  passengers
);

export const areAffiliatesVisible = (state) => {
  const outboundCarrier = outboundCarrierItinerary(state)?.toLowerCase();
  if (!outboundCarrier) return false;

  if (!PACKAGE_TRAVEL_UNLICENSED_CARRIERS.includes(outboundCarrier)) return true;
  return !(userGetters.isBrowsingFromEU(state) || userGetters.isEUCitizen(state));
};

export const isBookingDotComModalVisible = allPass(
  itineraryProp('isBookingDotComModalVisible'),
  areAffiliatesVisible,
  isBookingAvailable
);

export const isAutoCheckInSelected = itineraryProp('isAutoCheckInSelected');

export const isCarbonOffsetRibbonVisible = itineraryProp(
  'booking.displayCarbonOffsetRibbon'
);

export const advertisementBanner = itineraryProp('advertisementBanner');

export const getAutoCallMethodName = itineraryProp('autoCallMethodName');

export const bookingInformation = itineraryProp('booking.bookingInformation');

export const selectedTabDirection = itineraryProp('selectedTabDirection');

export const isManuallyAdded = itineraryProp('booking.isManuallyAdded');

export const isAddFlightExitIntentModalSeen = itineraryProp(
  'isAddFlightExitIntentModalSeen'
);

export const outboundFreeCheckinStartDate = itineraryProp('outboundFreeCheckinStartDate');
export const returnFreeCheckinStartDate = itineraryProp('returnFreeCheckinStartDate');

export const isComingFromSeatModificationCI = itineraryProp(
  'isComingFromSeatModificationCI'
);

export const disruptionInformation = itineraryProp('booking.disruptionInformation');

export const isOutboundFlightDisrupted = compose(
  propIsNotEmpty('outboundFlight'),
  disruptionInformation
);

export const isReturnFlightDisrupted = compose(
  propIsNotEmpty('returnFlight'),
  disruptionInformation
);
