const FLIGHT_STATUS_STATE_NONE = null;
export const FLIGHT_STATUS_STATE_ACCEPTED = 'accepted';
export const FLIGHT_STATUS_STATE_REFUNDED = 'refunded';
export const FLIGHT_STATUS_STATES = Object.freeze({
  FLIGHT_STATUS_STATE_NONE,
  FLIGHT_STATUS_STATE_ACCEPTED,
  FLIGHT_STATUS_STATE_REFUNDED,
});

export const SEAT_SELECTION_NEEDED = 'seatSelectionNeeded';

export const DISRUPTION_TYPE_NONE = 'none';
export const DISRUPTION_TYPE_TIME_CHANGE = 'timeChange';
export const DISRUPTION_TYPE_TIME_CHANGE_WO_OPTIONS = 'timeChangeWithoutOptions';
export const DISRUPTION_TYPE_FLIGHT_MOVE = 'flightMove';
export const DISRUPTION_TYPE_FLIGHT_MOVE_WITH_INFO = 'flightMoveWithInfo';
export const DISRUPTION_TYPE_CANCELLATION = 'cancellation';
export const DISRUPTION_TYPE_DISRUPTION = 'disruption';
export const DISRUPTION_TYPE_AIRCRAFT_TYPE_CHANGE = 'aircraftTypeChangedWithNewSeats';
export const DISRUPTION_TYPE_AIRCRAFT_TYPE_CHANGE_NO_NEW_SEATS =
  'aircraftTypeChangedWithNoNewSeats';
export const DISRUPTION_TYPE_CARRIER_CHANGE = 'carrier-change';
export const DISRUPTION_TYPE_RESCHEDULE = 'FlightReschedule';
export const DISRUPTION_TYPE_RESCHEDULE_WITH_INFO = 'FlightRescheduleWithInfo';
export const DISRUPTION_TYPE_REINSTATE = 'reinstateFlight';

export const DISRUPTION_TYPES = Object.freeze({
  DISRUPTION_TYPE_NONE,
  DISRUPTION_TYPE_TIME_CHANGE,
  DISRUPTION_TYPE_TIME_CHANGE_WO_OPTIONS,
  DISRUPTION_TYPE_FLIGHT_MOVE,
  DISRUPTION_TYPE_FLIGHT_MOVE_WITH_INFO,
  DISRUPTION_TYPE_CANCELLATION,
  DISRUPTION_TYPE_DISRUPTION,
  DISRUPTION_TYPE_AIRCRAFT_TYPE_CHANGE,
  DISRUPTION_TYPE_AIRCRAFT_TYPE_CHANGE_NO_NEW_SEATS,
  DISRUPTION_TYPE_RESCHEDULE_WITH_INFO,
  DISRUPTION_TYPE_RESCHEDULE,
  DISRUPTION_TYPE_REINSTATE,
});
