import { LS_FEATURE_FLAGS_DEBUG_KEY } from '~/constants/local-storage';
import trim from '~/utils/fp/trim';
import path from '~/utils/fp/path';
import assocPath from '~/utils/fp/assoc-path';
import alter from '~/utils/fp/alter';
import isNotEmpty from '~/utils/object/is-not-empty';
import { getLabelKeysForVariation } from '~/utils/localization';
import { setPersistentDimension } from '~/utils/analytics';
import { getLocalItemRaw, getSessionItemOr } from '~/utils/storage';

import {
  moduleName,
  fetchFeaturesStates,
  FEATURE_NAME_NO_CHECKED_IN_BAG_LABEL_TEST,
  FEATURE_NAME_LABEL_03,
  FEATURE_NAME_LABEL_05,
  FEATURE_NAME_LABEL_06,
  FEATURE_NAME_LABEL_07,
  FEATURE_NAME_LABEL_09,
  FEATURE_NAME_SEAT_RECOMMENDATION,
  FEATURE_NAME_PAYMENT_EXIT_INTENT_MODAL,
  FEATURE_NAME_ITINERARY_NEW_BOOKING_COM_MODAL_VISIBILITY,
  FEATURE_NAME_PROFILE_YOUR_CLAIMS,
  FEATURE_NAME_RENTAL_CARS_VISIBILITY,
  FEATURE_NAME_STICKY_BASKET_WITHOUT_SIDEBAR,
  FEATURE_NAME_WDC_DISCOUNT_APPLIED_ON_BAGS,
  FEATURE_NAME_IS_TRAVEL_PLANNING_MAP_SEARCH_COUNT_TRACKING,
  FEATURE_NAME_NEW_COOKIE_POLICY,
  FEATURE_NAME_PRM_SERVICE_ADDITION,
  FEATURE_NAME_HERO_BANNER_VARIANTS,
  FEATURE_NAME_CHECKED_IN_BAGGAGE_26_KG,
  FEATURE_NAME_SITTING_TOGETHER_ACCORDION,
  FEATURE_NAME_LOGIN_AT_SERVICES_STEP,
  FEATURE_NAME_TILE_SERVICES,
  FEATURE_NAME_CHEAP_FLIGHT_TRACKING_ENABLER,
  FEATURE_NAME_AIRSHIP_NOTIFICATION_DELAY,
  FEATURE_NAME_AIRSHIP_NOTIFICATION_MOBILE_DELAY,
  FEATURE_NAME_E_SIM_DATA_ROAMING,
  FEATURE_NAME_CHECK_IN_TEMPORARY_MESSAGE,
  FEATURE_NAME_INSURANCE_PRICING,
  FEATURE_NAME_RECAPTCHA_ON_REGISTRATION,
  FEATURE_NAME_SUPPLEMENTAL_PAYMENT,
  FEATURE_NAME_SMART_BUNDLE,
  FEATURE_NAME_POPULAR_TAG_ON_GO_BUNDLE,
  FEATURE_NAME_WDC_LIGHT,
  FEATURE_NAME_BLUE_RIBBON_BAG_TRACKING,
  FEATURE_NAME_MANDATORY_PRM_ON_BOOKING_FLOW,
  FEATURE_NAME_SMALL_BAGS,
  FEATURE_NAME_HIGHLIGHTED_WIZZ_GO,
  FEATURE_NAME_CHOOSE_SEAT_PROMO_PICTURE,
  FEATURE_NAME_CHECK_IN_EARLY,
  FEATURE_NAME_BUNDLE_PRICE_COMPARED_TO_BASIC,
  FEATURE_NAME_FLIGHT_PAST_PRICE,
  FEATURE_NAME_CHATBOT_PLACEHOLDER,
  FEATURE_NAME_SHOW_SEATMAP_BUTTON,
  FEATURE_NAME_WIZZ_GO_PER_PERSON_FLIGHT,
  FEATURE_NAME_SITTING_TOGETHER_PER_PERSON_FLIGHT,
  FEATURE_NAME_WIZZ_PRIORITY_BENEFITS_COPY,
  FEATURE_NAME_MOBILE_BOTTOM_MENU,
  FEATURE_NAME_CHECK_IN_LEGAL_INFORMATION,
  FEATURE_NAME_HIDE_FLIGHT_SEARCH_EVERYWHERE_DESKTOP,
  FEATURE_NAME_EXPEDIA_POPUNDER,
  FEATURE_NAME_CLOSE_PASSENGER_DETAILS,
  FEATURE_NAME_FLIGHT_DETAIL_BLOCK_REDESIGN,
  FEATURE_NAME_FARE_FINDER_PAST_PRICE,
  FEATURE_NAME_POPULAR_TAG,
  FEATURE_NAME_FLIGHT_DETAIL_BLOCK_CI_COMPONENT_MOBILE,
  FEATURE_NAME_SPLIT_PAX,
  FEATURE_NAME_NEW_PASSENGER_DETAILS,
  FEATURE_NAME_APP_LIKE_MOBILE_CARD,
  FEATURE_NAME_PROFILE_PRICE_ALERT,
  FEATURE_NAME_PRIVILEGE_PASS_ACTIVATION_NEW_BAG_PAGE,
  FEATURE_NAME_NEW_CHECK_IN_ALL_DONE_SEATING,
  FEATURE_NAME_RECOMMEND_26KG_BAG,
} from './constants';

const DEFAULT_FEATURE_OVERRIDE_VALUE = 'On';

export const getState = () => {
  const state = alter(getSessionItemOr({}, moduleName), getDefaultState());
  // note: edge case: page reload during PENDING state results in an invalid state
  //  PENDING without having an actual fetch in progress, so we reset the fetch state
  if (state.fetchFeaturesState === fetchFeaturesStates.PENDING) {
    state.fetchFeaturesState = fetchFeaturesStates.INITIAL;
  }
  return state;
};

const getDefaultState = () => ({
  testId: null,
  fetchFeaturesState: fetchFeaturesStates.INITIAL,
  features: [],
  originalFeatures: [],
  configCatSnapshot: null,
  initConfigCatCounter: 0,
});

export const isBlacklistedTrackableFeature = (feature) => {
  const name = feature.name;
  const value = getFeatureValue(feature);

  // INC387113 Acord insurance labels are tracked in the acord insurance component
  const isInsuranceLabel =
    value && value.includes('acord-insurance-service-description-');
  // REQ436179 no-checked-in-baggage option label tracked in checked-in-baggage component
  const isNoCheckedInBaggageLabel = name === FEATURE_NAME_NO_CHECKED_IN_BAG_LABEL_TEST;
  const isSeatRecommendationFeature = name === FEATURE_NAME_SEAT_RECOMMENDATION;
  const isPaymentExitIntentModalFeature = name === FEATURE_NAME_PAYMENT_EXIT_INTENT_MODAL;
  const isItineraryNewBookingDotComModalEnabled =
    name === FEATURE_NAME_ITINERARY_NEW_BOOKING_COM_MODAL_VISIBILITY;
  const isProfileYourClaimsEnabled = name === FEATURE_NAME_PROFILE_YOUR_CLAIMS;
  const isRentalCarsVisibilityFeature = name === FEATURE_NAME_RENTAL_CARS_VISIBILITY;
  const isStickyBasketWithoutSidebarEnabled =
    name === FEATURE_NAME_STICKY_BASKET_WITHOUT_SIDEBAR;
  const isWdcDiscountAppliedOnBagsFeature =
    name === FEATURE_NAME_WDC_DISCOUNT_APPLIED_ON_BAGS;

  const isTravelPlanningMapSearchCountTrackingFeature =
    name === FEATURE_NAME_IS_TRAVEL_PLANNING_MAP_SEARCH_COUNT_TRACKING;
  const isCheckedinBag26kgEnabled = name === FEATURE_NAME_CHECKED_IN_BAGGAGE_26_KG;
  const isSittingTogetherAccordionEnabled =
    name === FEATURE_NAME_SITTING_TOGETHER_ACCORDION;

  const isNewCookiePolicy = name === FEATURE_NAME_NEW_COOKIE_POLICY;

  const isNewPrm = name === FEATURE_NAME_PRM_SERVICE_ADDITION;

  const isHeroBannerVariantsFeature = name === FEATURE_NAME_HERO_BANNER_VARIANTS;
  const isLoginAtServicesStepFeature = name === FEATURE_NAME_LOGIN_AT_SERVICES_STEP;

  const isSmartBundleEnabled = name === FEATURE_NAME_SMART_BUNDLE;

  const isPopularTagOnGoBundleEnabled = name === FEATURE_NAME_POPULAR_TAG_ON_GO_BUNDLE;

  const isTileServicesEnabled = name === FEATURE_NAME_TILE_SERVICES;

  const isCheapFlightTrackingEnabled =
    name === FEATURE_NAME_CHEAP_FLIGHT_TRACKING_ENABLER;

  const isAirshipNotificationDelayFeature =
    name === FEATURE_NAME_AIRSHIP_NOTIFICATION_DELAY;
  const isAirshipNotificationMobileDelayFeature =
    name === FEATURE_NAME_AIRSHIP_NOTIFICATION_MOBILE_DELAY;

  const isESimDataRoamingFeature = name === FEATURE_NAME_E_SIM_DATA_ROAMING;

  const isCheckInTemporaryMessageFeature =
    name === FEATURE_NAME_CHECK_IN_TEMPORARY_MESSAGE;

  const isInsurancePricingFeature = name === FEATURE_NAME_INSURANCE_PRICING;

  const isRecaptchaOnRegistrationEnabled =
    name === FEATURE_NAME_RECAPTCHA_ON_REGISTRATION;

  const isSupplementalPaymentFeature = name === FEATURE_NAME_SUPPLEMENTAL_PAYMENT;

  const isBlueRibbonBagTrackingFeature = name === FEATURE_NAME_BLUE_RIBBON_BAG_TRACKING;

  const isWdcLightFeature = name === FEATURE_NAME_WDC_LIGHT;

  const isMandatoryPrmFeature = name === FEATURE_NAME_MANDATORY_PRM_ON_BOOKING_FLOW;

  const isExpediaFeature = name === FEATURE_NAME_EXPEDIA_POPUNDER;

  const isSmallBagsFeature = name === FEATURE_NAME_SMALL_BAGS;

  const isHighlightedWizzGoFeature = name === FEATURE_NAME_HIGHLIGHTED_WIZZ_GO;

  const isChooseSeatPromoPictureFeature = name === FEATURE_NAME_CHOOSE_SEAT_PROMO_PICTURE;

  const isCheckInEarlyFeature = name === FEATURE_NAME_CHECK_IN_EARLY;

  const isBundlePriceComparedToBasicFeature =
    name === FEATURE_NAME_BUNDLE_PRICE_COMPARED_TO_BASIC;

  const isFlightPastPriceFeature = name === FEATURE_NAME_FLIGHT_PAST_PRICE;

  const isChatbotPlaceholderFeature = name === FEATURE_NAME_CHATBOT_PLACEHOLDER;

  const isShowSeatmapButtonFeature = name === FEATURE_NAME_SHOW_SEATMAP_BUTTON;

  const isWizzGoPerPersonFlightFeature = name === FEATURE_NAME_WIZZ_GO_PER_PERSON_FLIGHT;

  const isSittingTogetherPerPersonFlightFeature =
    name === FEATURE_NAME_SITTING_TOGETHER_PER_PERSON_FLIGHT;

  const isWizzPriorityBenefitsCopyFeature =
    name === FEATURE_NAME_WIZZ_PRIORITY_BENEFITS_COPY;

  const isMobileBottomMenuFeature = name === FEATURE_NAME_MOBILE_BOTTOM_MENU;

  const isCheckInLegalInformationFeature =
    name === FEATURE_NAME_CHECK_IN_LEGAL_INFORMATION;

  const isHideFlightSearchEverywhereDesktopFeature =
    name === FEATURE_NAME_HIDE_FLIGHT_SEARCH_EVERYWHERE_DESKTOP;

  const isClosePassengerDetailsFeature = name === FEATURE_NAME_CLOSE_PASSENGER_DETAILS;

  const isFlightDetailBlockFeature = name === FEATURE_NAME_FLIGHT_DETAIL_BLOCK_REDESIGN;

  const isFareFinderPastPriceFeature = name === FEATURE_NAME_FARE_FINDER_PAST_PRICE;

  const isPopularTagFeature = name === FEATURE_NAME_POPULAR_TAG;

  const isSplitPaxFeature = name === FEATURE_NAME_SPLIT_PAX;

  const isFlightDetailBlockCIComponentMobileFeature =
    name === FEATURE_NAME_FLIGHT_DETAIL_BLOCK_CI_COMPONENT_MOBILE;

  const isNewPassengerDetailsFeature = name === FEATURE_NAME_NEW_PASSENGER_DETAILS;

  // REQ436199 Post-booking features: [AB] Car Renting - button text test [WEB]
  const isLabel03 = name === FEATURE_NAME_LABEL_03;
  const isLabel05 = name === FEATURE_NAME_LABEL_05;
  const isLabel06 = name === FEATURE_NAME_LABEL_06;
  const isLabel07 = name === FEATURE_NAME_LABEL_07;
  const isLabel09 = name === FEATURE_NAME_LABEL_09;

  const isAppLikeMobileCardFeature = name === FEATURE_NAME_APP_LIKE_MOBILE_CARD;

  const isProfilePriceAlertFeature = name === FEATURE_NAME_PROFILE_PRICE_ALERT;

  const isPrivilegePassActivationNewBagPageFeature =
    name === FEATURE_NAME_PRIVILEGE_PASS_ACTIVATION_NEW_BAG_PAGE;

  const isNewCheckInAllDoneSeatingFeature =
    name === FEATURE_NAME_NEW_CHECK_IN_ALL_DONE_SEATING;

  const isRecommend26KgBagFeature = name === FEATURE_NAME_RECOMMEND_26KG_BAG;

  return [
    isInsuranceLabel,
    isNoCheckedInBaggageLabel,
    isLabel03,
    isLabel05,
    isLabel07,
    isLabel06,
    isLabel09,
    isSeatRecommendationFeature,
    isPaymentExitIntentModalFeature,
    isItineraryNewBookingDotComModalEnabled,
    isProfileYourClaimsEnabled,
    isRentalCarsVisibilityFeature,
    isStickyBasketWithoutSidebarEnabled,
    isWdcDiscountAppliedOnBagsFeature,
    isTravelPlanningMapSearchCountTrackingFeature,
    isNewCookiePolicy,
    isNewPrm,
    isCheckedinBag26kgEnabled,
    isSittingTogetherAccordionEnabled,
    isHeroBannerVariantsFeature,
    isLoginAtServicesStepFeature,
    isSmartBundleEnabled,
    isPopularTagOnGoBundleEnabled,
    isTileServicesEnabled,
    isCheapFlightTrackingEnabled,
    isAirshipNotificationDelayFeature,
    isAirshipNotificationMobileDelayFeature,
    isESimDataRoamingFeature,
    isCheckInTemporaryMessageFeature,
    isInsurancePricingFeature,
    isRecaptchaOnRegistrationEnabled,
    isSupplementalPaymentFeature,
    isWdcLightFeature,
    isBlueRibbonBagTrackingFeature,
    isMandatoryPrmFeature,
    isExpediaFeature,
    isSmallBagsFeature,
    isHighlightedWizzGoFeature,
    isChooseSeatPromoPictureFeature,
    isCheckInEarlyFeature,
    isBundlePriceComparedToBasicFeature,
    isFlightPastPriceFeature,
    isChatbotPlaceholderFeature,
    isShowSeatmapButtonFeature,
    isWizzGoPerPersonFlightFeature,
    isSittingTogetherPerPersonFlightFeature,
    isWizzPriorityBenefitsCopyFeature,
    isMobileBottomMenuFeature,
    isCheckInLegalInformationFeature,
    isHideFlightSearchEverywhereDesktopFeature,
    isClosePassengerDetailsFeature,
    isFlightDetailBlockFeature,
    isFareFinderPastPriceFeature,
    isPopularTagFeature,
    isFlightDetailBlockCIComponentMobileFeature,
    isSplitPaxFeature,
    isNewPassengerDetailsFeature,
    isAppLikeMobileCardFeature,
    isProfilePriceAlertFeature,
    isPrivilegePassActivationNewBagPageFeature,
    isNewCheckInAllDoneSeatingFeature,
    isRecommend26KgBagFeature,
  ].some(Boolean);
};

/**
 * @type {(feature: Object<string, any>) => any}
 */
export const getFeatureValue = path('featureInfo.value');

/**
 * @type {(features: Object<string, any>[]) => Object<string, any>[]}
 */
export const overrideFeatureValues = (features) => {
  const featureOverrides = getFeatureOverrides();
  return features.map((feature) => {
    const { name } = feature;
    const newValue = featureOverrides[name];
    if (!newValue || !feature.featureInfo) return feature;
    return assocPath('featureInfo.value', newValue, feature);
  });
};

/**
 * Feature overrides is a comma limited string in local storage under the `featureFlags`
 * key. We can define feature overrides simply by name like `FeatureA` these will
 * have the default value `On`. We can set a different value like this: `FeatureB:Value`
 *
 * @example
 *
 *  featureFlags: 'FeatureA,FeatureB:On,FeatureC:Yes,FeatureD:Si,FeatureE:true,FeatureF'
 *
 *  output:
 *    {
 *      FeatureA: 'On',
 *      FeatureB: 'On',
 *      FeatureC: 'Yes',
 *      FeatureD: 'Si',
 *      FeatureE: 'true',
 *      FeatureF: 'On',
 *    }
 *
 * @type {() => Object<string, string>}
 */
const getFeatureOverrides = () => {
  const rawOverrides = getLocalItemRaw(LS_FEATURE_FLAGS_DEBUG_KEY) || '';
  return rawOverrides
    .split(',')
    .map(trim)
    .filter(isNotEmpty)
    .reduce((acc, rawOverride) => {
      const [name, value] = rawOverride.split(':');
      acc[name] = value || DEFAULT_FEATURE_OVERRIDE_VALUE;
      return acc;
    }, {});
};

export const isOverriddenFeature = (name) =>
  Object.keys(getFeatureOverrides()).includes(name);

export const trackFeatureVersion = (feature) => {
  const value = feature.name.startsWith('Label')
    ? getLabelKeysForVariation({
        name: feature.name,
        value: getFeatureValue(feature),
      })
    : getFeatureValue(feature);
  const dimension = feature?.featureInfo?.dimension?.index;

  if (value && dimension) {
    trackFeature({
      dimension,
      value,
    });
  }
};

const trackFeature = ({ dimension = '', value = '' } = {}) => {
  setPersistentDimension(dimension, value);
};
