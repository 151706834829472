import complement from '~/utils/fp/complement';
import compose from '~/utils/fp/compose';
import equals from '~/utils/fp/equals';
import isNotEmpty from '~/utils/object/is-not-empty';
import { DIRECTION_OUTBOUND, DIRECTION_RETURN } from '~/constants';
import either from '~/utils/fp/either';
import gt from '~/utils/fp/gt';
import __ from '~/utils/fp/__';
import applySpec from '~/utils/fp/apply-spec';
import {
  DISRUPTION_TYPE_NONE,
  DISRUPTION_TYPE_TIME_CHANGE,
  DISRUPTION_TYPE_TIME_CHANGE_WO_OPTIONS,
  DISRUPTION_TYPE_FLIGHT_MOVE,
  DISRUPTION_TYPE_CANCELLATION,
  DISRUPTION_TYPE_AIRCRAFT_TYPE_CHANGE,
  DISRUPTION_TYPE_AIRCRAFT_TYPE_CHANGE_NO_NEW_SEATS,
  FLIGHT_STATUS_STATE_REFUNDED,
  DISRUPTION_TYPE_DISRUPTION,
  DISRUPTION_TYPE_RESCHEDULE,
  DISRUPTION_TYPE_RESCHEDULE_WITH_INFO,
  DISRUPTION_TYPE_REINSTATE,
} from '~/constants/flight-disruption';
import both from '~/utils/fp/both';
import { flightDisruptionProp } from './internal';

export const disruptionType = flightDisruptionProp('disruptionType');

const outboundFlight = flightDisruptionProp('outboundFlight');

const returnFlight = flightDisruptionProp('returnFlight');

export const thirdPartyNeedChange = flightDisruptionProp('thirdPartyNeedChange');

export const shouldAcceptCarrierChange = flightDisruptionProp(
  'shouldAcceptCarrierChange'
);

export const acceptanceCarrierCode = flightDisruptionProp('acceptanceCarrierCode');

export const flights = applySpec({
  [DIRECTION_OUTBOUND]: outboundFlight,
  [DIRECTION_RETURN]: returnFlight,
});

export const isFlightDisrupted = either(
  compose(complement(equals(DISRUPTION_TYPE_NONE)), disruptionType),
  shouldAcceptCarrierChange
);

export const isFlightRescheduled = compose(
  either(
    equals(DISRUPTION_TYPE_RESCHEDULE),
    equals(DISRUPTION_TYPE_RESCHEDULE_WITH_INFO)
  ),
  disruptionType
);

export const isFlightTimeChanged = compose(
  equals(DISRUPTION_TYPE_TIME_CHANGE),
  disruptionType
);

export const isFlightTimeChangedWithoutOptions = compose(
  equals(DISRUPTION_TYPE_TIME_CHANGE_WO_OPTIONS),
  disruptionType
);

export const isFlightMovedWithoutInfo = compose(
  equals(DISRUPTION_TYPE_FLIGHT_MOVE),
  disruptionType
);

export const isFlightCancelled = compose(
  either(equals(DISRUPTION_TYPE_CANCELLATION), equals(DISRUPTION_TYPE_DISRUPTION)),
  disruptionType
);

export const isReinstateFlight = compose(
  equals(DISRUPTION_TYPE_REINSTATE),
  disruptionType
);

export const isOutboundFlightDisrupted = compose(isNotEmpty, outboundFlight);

export const isReturnFlightDisrupted = compose(isNotEmpty, returnFlight);

export const isAircraftChanged = compose(
  either(
    equals(DISRUPTION_TYPE_AIRCRAFT_TYPE_CHANGE),
    equals(DISRUPTION_TYPE_AIRCRAFT_TYPE_CHANGE_NO_NEW_SEATS)
  ),
  disruptionType
);

export const isAircraftChangedAndNoLostSeats = compose(
  equals(DISRUPTION_TYPE_AIRCRAFT_TYPE_CHANGE),
  disruptionType
);

export const isFlightDisruptedAndHasLostSeats = both(
  isFlightDisrupted,
  complement(isAircraftChangedAndNoLostSeats)
);

export const canRebook = flightDisruptionProp('canRebook');

export const canAcceptNewFlight = flightDisruptionProp('canAcceptNewFlight');

export const canRefund = flightDisruptionProp('canRefund');

export const changedPassengers = flightDisruptionProp('changedPassengers');

export const isLoading = compose(gt(__, 0), flightDisruptionProp('loadingCounter'));

export const shouldReselectSeats = flightDisruptionProp('shouldReselectSeats');

export const rebookToSeatFlight = flightDisruptionProp('rebookToSeatFlight');

export const flightStatusChangedState = flightDisruptionProp('flightStatusChangedState');

export const seatSelectionNeededPassengerList = flightDisruptionProp(
  'seatSelectionNeededPassengerList'
);

export const errors = flightDisruptionProp('errors');

export const refundOptions = flightDisruptionProp('refundOptions');

export const isOutboundRefundOptionSelected = flightDisruptionProp(
  'selectedRefundOptions.outbound'
);

export const isReturnRefundOptionSelected = flightDisruptionProp(
  'selectedRefundOptions.return'
);

export const isRefunded = compose(
  equals(FLIGHT_STATUS_STATE_REFUNDED),
  flightStatusChangedState
);

export const creditBackBonusPercent = flightDisruptionProp('creditBackBonusPercent');
