import { isSSRBuild } from '~/utils/ssr/build-type';
import {
  ANCILLARY_ACORD_SERVICE,
  ANCILLARY_AIRPORT_CHECK_IN,
  ANCILLARY_PRIORITY_BOARDING,
  ANCILLARY_SMS_NOTIFICATION,
  ANCILLARY_WIZZ_FLEX,
  ANCILLARY_WDC_MEMBERSHIP,
  STEP_SELECT_FLIGHT,
  STEP_PASSENGERS,
  STEP_SEAT_SELECTION,
  STEP_SERVICES,
  STEP_PAYMENT,
  STEP_SUCCESSFUL_PAYMENT,
  WDC_MEMBERSHIP,
  DC_MEMBERSHIP,
  ANCILLARY_AUTO_CHECK_IN,
  ANCILLARY_SEAT,
  ANCILLARY_SEATING_TOGETHER_GUARANTEE,
} from '..';

export const TEST_TRACKING_ID = 'UA-2629375-24';
export const PRODUCTION_TRACKING_ID = 'UA-2629375-25';

export const PLATFORM_EWIZZ = `${isSSRBuild ? 'ssr-' : 'e'}wizz`;

export const PRODUCT_VARIANT_FAMILY = 'family';
export const PRODUCT_VARIANT_NON_FAMILY = 'non family';

export const CART_ACTION_ADD = 'sendEcommerceAddToCartEvent';
export const CART_ACTION_REMOVE = 'sendEcommerceRemoveFromCartEvent';

export const ANALYTICS_CURRENCY_CODE = 'EUR';
// Make sure to update the Funnel Steps in Analytics, in case you change this
export const ANALYTICS_CHECKOUT_STEP_ITINERARY = 7;
export const ANALYTICS_EXCLUDED_FROM_HASHCHANGE = ['fareFinder', 'running'];
export const ANALYTICS_EXCLUDED = ['itineraryNew', 'itinerary'];

export const CATEGORY_ANCILLARY = 'ancillary';

export const ANALYTICS_CAR_RENTAL_CODE = 'Car rental';

export const ANALYTICS_CAR_TRAWLER_CODE = 'Cartrawler';

export const BOOKING_FLOW_ANALYTICS_STEPS = new Map()
  .set(STEP_SELECT_FLIGHT, 1)
  .set(STEP_PASSENGERS, 2)
  .set(STEP_SEAT_SELECTION, 3)
  .set(STEP_SERVICES, 4)
  .set(STEP_PAYMENT, 5)
  .set(STEP_SUCCESSFUL_PAYMENT, 6);

export const ANALYTICS_PRODUCT_WIZZ_PRIORITY = 'WIZZ Priority Boarding';
export const ANALYTICS_PRODUCT_SMS = 'SMS ';
export const ANALYTICS_PRODUCT_AIRPORT_CHECK_IN = 'Airport check-in fee';
export const ANALYTICS_PRODUCT_CANCELLATION_INSURANCE = 'Cancellation insurance';
export const ANALYTICS_PRODUCT_TRAVEL_INSURANCE = 'Travel insurance';
export const ANALYTICS_PRODUCT_WIZZ_FLEX = 'WIZZ Flex';
export const ANALYTICS_PRODUCT_AUTO_CHECK_IN = 'Auto Check-in';
export const ANALYTICS_PRODUCT_ALLOCATED_SEATING = 'Allocated seating';
export const ANALYTICS_PRODUCT_PRM = 'PRM';
export const ANALYTICS_PRODUCT_SITTING_TOGETHER = 'Sitting together';

export const ECOMMERCE_PRODUCT_TO_ID_MAP = new Map([
  [ANALYTICS_PRODUCT_WIZZ_PRIORITY, ANCILLARY_PRIORITY_BOARDING],
  [ANALYTICS_PRODUCT_SMS, ANCILLARY_SMS_NOTIFICATION],
  [ANALYTICS_PRODUCT_AIRPORT_CHECK_IN, ANCILLARY_AIRPORT_CHECK_IN],
  [WDC_MEMBERSHIP, ANCILLARY_WDC_MEMBERSHIP],
  [DC_MEMBERSHIP, ANCILLARY_WDC_MEMBERSHIP],
  [ANALYTICS_PRODUCT_WIZZ_FLEX, ANCILLARY_WIZZ_FLEX],
  [ANALYTICS_PRODUCT_CANCELLATION_INSURANCE, ANCILLARY_ACORD_SERVICE],
  [ANALYTICS_PRODUCT_TRAVEL_INSURANCE, ANCILLARY_ACORD_SERVICE],
  [ANALYTICS_PRODUCT_AUTO_CHECK_IN, ANCILLARY_AUTO_CHECK_IN],
  [ANALYTICS_PRODUCT_ALLOCATED_SEATING, ANCILLARY_SEAT],
  [ANALYTICS_PRODUCT_SITTING_TOGETHER, ANCILLARY_SEATING_TOGETHER_GUARANTEE],
]);

const EXTRA_LIGHT_BAG_LOW_SEASON = 'TBGL';
const EXTRA_LIGHT_BAG_LOW_SEASON_POST_BOOKING_FLOW = 'TBGL1';
const EXTRA_LIGHT_BAG_HIGH_SEASON = 'TBGH';
const EXTRA_LIGHT_BAG_HIGH_SEASON_POST_BOOKING_FLOW = 'TBGH1';
const ULTRA_LIGHT_BAG_LOW_SEASON = 'UBGL';
const ULTRA_LIGHT_BAG_LOW_SEASON_POST_BOOKING_FLOW = 'UBGL1';
const ULTRA_LIGHT_BAG_HIGH_SEASON = 'UBGH';
const ULTRA_LIGHT_BAG_HIGH_SEASON_POST_BOOKING_FLOW = 'UBGH1';
const MEDIUM_BAG_LOW_SEASON = 'MBGL';
const MEDIUM_BAG_LOW_SEASON_POST_BOOKING_FLOW = 'MBGL1';
const MEDIUM_BAG_HIGH_SEASON = 'MBGH';
const MEDIUM_BAG_HIGH_SEASON_POST_BOOKING_FLOW = 'MBGH1';
const HEAVY_BAG_LOW_SEASON = 'BAGL';
const HEAVY_BAG_LOW_SEASON_POST_BOOKING_FLOW = 'BAGL1';
const HEAVY_BAG_HIGH_SEASON = 'BAGH';
const HEAVY_BAG_HIGH_SEASON_POST_BOOKING_FLOW = 'BAGH1';

export const BAG_CODE_TO_VARIANT_MAP = new Map();
BAG_CODE_TO_VARIANT_MAP.set(EXTRA_LIGHT_BAG_LOW_SEASON, '10 kg')
  .set(EXTRA_LIGHT_BAG_LOW_SEASON_POST_BOOKING_FLOW, '10 kg')
  .set(EXTRA_LIGHT_BAG_HIGH_SEASON, '10 kg')
  .set(EXTRA_LIGHT_BAG_HIGH_SEASON_POST_BOOKING_FLOW, '10 kg')
  .set(ULTRA_LIGHT_BAG_LOW_SEASON, '20 kg')
  .set(ULTRA_LIGHT_BAG_LOW_SEASON_POST_BOOKING_FLOW, '20 kg')
  .set(ULTRA_LIGHT_BAG_HIGH_SEASON, '20 kg')
  .set(ULTRA_LIGHT_BAG_HIGH_SEASON_POST_BOOKING_FLOW, '20 kg')
  .set(MEDIUM_BAG_LOW_SEASON, '26 kg')
  .set(MEDIUM_BAG_LOW_SEASON_POST_BOOKING_FLOW, '26 kg')
  .set(MEDIUM_BAG_HIGH_SEASON, '26 kg')
  .set(MEDIUM_BAG_HIGH_SEASON_POST_BOOKING_FLOW, '26 kg')
  .set(HEAVY_BAG_LOW_SEASON, '32 kg')
  .set(HEAVY_BAG_LOW_SEASON_POST_BOOKING_FLOW, '32 kg')
  .set(HEAVY_BAG_HIGH_SEASON, '32 kg')
  .set(HEAVY_BAG_HIGH_SEASON_POST_BOOKING_FLOW, '32 kg');

export const DIMENSION_USER_ID = {
  [TEST_TRACKING_ID]: 'dimension18',
  [PRODUCTION_TRACKING_ID]: 'dimension26',
};

export const DIMENSION_IS_SUBSCRIBED_TO_NEWSLETTER = {
  [TEST_TRACKING_ID]: 'dimension11',
  [PRODUCTION_TRACKING_ID]: 'dimension11',
};

export const DIMENSION_BIGQUERY_SEARCH_ORIGIN_ID = {
  [TEST_TRACKING_ID]: 'dimension19',
  [PRODUCTION_TRACKING_ID]: 'dimension16',
};

export const DIMENSION_BIGQUERY_SEARCH_DESTINATION_ID = {
  [TEST_TRACKING_ID]: 'dimension20',
  [PRODUCTION_TRACKING_ID]: 'dimension17',
};

export const DIMENSION_BIGQUERY_SEARCH_STARTDATE = {
  [TEST_TRACKING_ID]: 'dimension21',
  [PRODUCTION_TRACKING_ID]: 'dimension18',
};

export const DIMENSION_BIGQUERY_SEARCH_ENDDATE = {
  [TEST_TRACKING_ID]: 'dimension22',
  [PRODUCTION_TRACKING_ID]: 'dimension19',
};

export const DIMENSION_BIGQUERY_SEARCH_TOTAL_PAX = {
  [TEST_TRACKING_ID]: 'dimension23',
  [PRODUCTION_TRACKING_ID]: 'dimension20',
};

export const DIMENSION_BIGQUERY_SEARCH_PAX_TYPE = {
  [TEST_TRACKING_ID]: 'dimension24',
  [PRODUCTION_TRACKING_ID]: 'dimension21',
};

export const DIMENSION_BIGQUERY_SEARCH_NEW_DESTINATION_TYPE = {
  [TEST_TRACKING_ID]: 'dimension71',
  [PRODUCTION_TRACKING_ID]: 'dimension69',
};

export const DIMENSION_SEARCHED_ROUTES = {
  [TEST_TRACKING_ID]: 'dimension80',
  [PRODUCTION_TRACKING_ID]: 'dimension29',
};

export const DIMENSION_SEARCHED_DATE = {
  [TEST_TRACKING_ID]: 'dimension60',
  [PRODUCTION_TRACKING_ID]: 'dimension28',
};

export const METRIC_RESPONSE_END_TIME = {
  [TEST_TRACKING_ID]: 'metric2',
  [PRODUCTION_TRACKING_ID]: 'metric1',
};

export const METRIC_DOM_LOAD_TIME = {
  [TEST_TRACKING_ID]: 'metric3',
  [PRODUCTION_TRACKING_ID]: 'metric2',
};

export const METRIC_WINDOW_LOAD_TIME = {
  [TEST_TRACKING_ID]: 'metric4',
  [PRODUCTION_TRACKING_ID]: 'metric3',
};

export const DIMENSION_CLIENT_ID = {
  [TEST_TRACKING_ID]: 'dimension25',
  [PRODUCTION_TRACKING_ID]: 'dimension22',
};

export const TRIP_PLANNER_LEAVING_FROM = {
  [TEST_TRACKING_ID]: 'dimension58',
  [PRODUCTION_TRACKING_ID]: 'dimension43',
};

export const TRIP_PLANNER_LOOKING_FOR = {
  [TEST_TRACKING_ID]: 'dimension51',
  [PRODUCTION_TRACKING_ID]: 'dimension44',
};

export const TRIP_PLANNER_DATE = {
  [TEST_TRACKING_ID]: 'dimension52',
  [PRODUCTION_TRACKING_ID]: 'dimension45',
};

export const TRIP_PLANNER_DURATION = {
  [TEST_TRACKING_ID]: 'dimension53',
  [PRODUCTION_TRACKING_ID]: 'dimension46',
};

export const TRIP_PLANNER_PRICE = {
  [TEST_TRACKING_ID]: 'dimension54',
  [PRODUCTION_TRACKING_ID]: 'dimension47',
};

export const TRIP_VIEW_FLIGHTS = {
  [TEST_TRACKING_ID]: 'dimension56',
  [PRODUCTION_TRACKING_ID]: 'dimension48',
};

export const LOYALTY_COOKIE = {
  [TEST_TRACKING_ID]: 'dimension61',
  [PRODUCTION_TRACKING_ID]: 'dimension63',
};

export const DIMENSION_PROMOTIONS_SEEN = {
  [TEST_TRACKING_ID]: 'dimension72',
  [PRODUCTION_TRACKING_ID]: 'dimension76',
};

export const DIMENSION_BUILD_NUMBER = {
  [TEST_TRACKING_ID]: 'dimension74',
  [PRODUCTION_TRACKING_ID]: 'dimension77',
};

export const DIMENSION_WDC_SAVING = {
  [TEST_TRACKING_ID]: 'dimension76',
  [PRODUCTION_TRACKING_ID]: 'dimension23',
};

export const DIMENSION_STICKY_FOOTER = {
  [TEST_TRACKING_ID]: 'dimension77',
  [PRODUCTION_TRACKING_ID]: 'dimension93',
};

export const DIMENSION_VAR_99 = {
  // #20036
  [TEST_TRACKING_ID]: 67,
  [PRODUCTION_TRACKING_ID]: 68,
};

export const DIMENSION_PRODUCT_PRICE = {
  [TEST_TRACKING_ID]: 'dimension78',
  [PRODUCTION_TRACKING_ID]: 'dimension95',
};

export const DIMENSION_EVENT_SOURCE = {
  [TEST_TRACKING_ID]: 'dimension12',
  [PRODUCTION_TRACKING_ID]: 'dimension12',
};

export const DIMENSION_CHEAP_FLIGHT_CONNECTION = {
  [TEST_TRACKING_ID]: 'dimension13',
  [PRODUCTION_TRACKING_ID]: 'dimension13',
};

export const DIMENSION_CHEAP_FLIGHT_POSITION = {
  [TEST_TRACKING_ID]: 'dimension15',
  [PRODUCTION_TRACKING_ID]: 'dimension15',
};

export const DIMENSION_CHEAP_FLIGHT_CATEGORY = {
  [TEST_TRACKING_ID]: 'dimension82',
  [PRODUCTION_TRACKING_ID]: 'dimension98',
};

// possible values: ewizz / onewizz
export const DIMENSION_PLATFORM = {
  [TEST_TRACKING_ID]: 'dimension79',
  [PRODUCTION_TRACKING_ID]: 'dimension96',
};

export const DIMENSION_USER_WDC_TYPE = {
  [TEST_TRACKING_ID]: 'dimension81',
  [PRODUCTION_TRACKING_ID]: 'dimension97',
};

export const GA_ACTION_BEGIN_CHECKOUT = 'begin_checkout';
export const GA_ACTION_CHECKOUT_PROGRESS = 'checkout_progress';

// phone number
export const GA_PHONE_NUMBER_EVENT = {
  category: {
    update: 'Mobile phone number update',
  },
  action: {
    click: 'Click',
  },
  label: {
    updateModal: 'Landing / Update phone number modal',
    profileYourProfile: 'Profile / Your profile',
    itineraryEditModal: 'Itinerary / Edit phone number modal',
    checkInTravelDocuments: 'Check in / Travel documents',
    checkInContactData: 'Check in / Contact data',
  },
};

export const GA_EVENT_CATEGORY_WDC_INFO_PAGE = 'WDC Info Page';

export const GA_EVENT_CATEGORY_WDC_ITALY_INFO_PAGE = 'WIZZ Italy Domestic Club Info Page';
export const GA_EVENT_ACTION_WDC_INFO_CALCULATE = 'Calculate';
export const GA_EVENT_ACTION_WDC_INFO_CALCULATE_SCROLL = 'Calculate scroll';
export const GA_EVENT_ACTION_WDC_INFO_TRAVEL_TIMES =
  'How many times do you plan to travel in the next 12 months?';
export const GA_EVENT_ACTION_WDC_INFO_PASSENGER_NUMBER =
  'How many of you will travel together (including you)?';
export const GA_EVENT_ACTION_WDC_INFO_CHECKED_IN_BAG =
  'Do you usually travel with checked-in baggage?';
export const GA_EVENT_ACTION_WDC_INFO_WIZZ_PRIORITY =
  'Do you usually travel with WIZZ priority?';
export const GA_EVENT_ACTION_WDC_INFO_BOOK_AND_PURCHASE = 'Book now and purchase WDC';
export const GA_EVENT_ACTION_WDC_INFO_GO_TO_PROFILE = 'Go to Profile';
export const GA_EVENT_LABEL_WDC_INFO_HAS_CHECKED_IN_BAG = 'Yes, with checked-in baggage';
export const GA_EVENT_LABEL_WDC_INFO_HAS_WIZZ_PRIORITY = 'Yes, with WIZZ priority';
export const GA_EVENT_LABEL_WDC_INFO_WITHOUT = 'No, without it';
export const GA_EVENT_LABEL_WDC_LIGHT = 'WDC Light';
export const GA_EVENT_LABEL_WDC_STANDARD = 'WDC Standard';
export const GA_EVENT_LABEL_WDC_GROUP = 'WDC Group';
export const GA_EVENT_ACTION_START_BOOKING = 'Start Booking';
export const GA_EVENT_LABEL_CALCULATOR = 'Calculator';

export const GA_OPENING_SOURCE_WDC_PROMOTION = 'WDC Promotion';

export const GA_EVENT_CATEGORY_STICKY_BASKET = 'Summary';
export const GA_EVENT_LABEL_STICKY_BASKET_OPEN = 'Open';
export const GA_EVENT_LABEL_STICKY_BASKET_CLOSED = 'Closed';

export const GA_EVENT_CATEGORY_CHATBOT = 'Chatbot';
export const GA_EVENT_ACTION_CHATBOT_OPENED = 'Opened';
export const GA_EVENT_LABEL_CHATBOT_OPENED = 'Chatbot opened';
export const GA_EVENT_LABEL_SHOW_PRICE_CHART = 'Show price Chart';
export const GA_EVENT_LABEL_HIDE_PRICE_CHART = 'Hide price Chart';

export const GA_EVENT_CATEGORY_BUNDLE_NAVIGATION = 'Navigation in bundles';
export const GA_EVENT_LABEL_NORMAL_PRICE_CANCELED = 'Cancel from normal prices';
export const GA_EVENT_LABEL_WDC_PRICE_CANCELED = 'Cancel from WDC prices';

export const GA_EVENT_CATEGORY_YOUR_CLAIMS = 'Your Claims';
export const GA_EVENT_ACTION_OPEN_POPUP = 'Open popup';
export const GA_EVENT_LABEL_PROFILE = 'Profile';
export const GA_EVENT_LABEL_CLAIM_SUBMIT = 'Claim submit';

export const GA_EVENT_CATEGORY_CLAIM_FORM = 'Claim Form';
export const GA_EVENT_ACTION_SELF_SERVICE_REDIRECTION = 'Redirection to self service';
export const GA_EVENT_LABEL_SELF_SERVICE = 'Self-service';

export const GA_EVENT_ACTION_AMELIA_CHATBOT_OPENED = 'Amelia chatbot opened';
export const GA_EVENT_ACTION_STILL_WANT_TO_CLAIM_CLICK = 'Still want to claim - Click';
export const GA_EVENT_ACTION_STILL_WANT_TO_CLAIM_TRAVEL_AGENCY_CLICK =
  'Still want to claim - Travel agency - Click';
export const GA_EVENT_ACTION_VALIDATION_MESSAGE_LOADED_WITH_CHECKBOX =
  'Validation message loaded with checkbox';
export const GA_EVENT_ACTION_PREVENTION_PNR_NOT_FOUND = 'Prevention - PNR not found';

export const GA_EVENT_CATEGORY_NEWSLETTER = 'Newsletter';
export const GA_EVENT_ACTION_SUBSCRIBE = 'Subscribe';
export const GA_EVENT_LABEL_LOGIN = 'Login';
export const GA_EVENT_LABEL_REGISTRATION = 'Registration';

export const GA_EVENT_LABEL_PRM_APPLY = 'Applied for PRM';
export const GA_EVENT_CATEGORY_PRM = 'PRM';
export const GA_EVENT_CATEGORY_WEB_PUSH = 'Web push';
export const GA_EVENT_CATEGORY_PARTNER_SERVICE = 'Partner Service';
export const GA_EVENT_LABEL_CHECK_IN_ALL_DONE = 'Check-in all done';
export const GA_EVENT_LABEL_E_SIM_ITINERARY = 'eSim - Itinerary';
export const GA_EVENT_LABEL_BOOKING_ITINERARY = 'Booking - Itinerary';

export const GA_EVENT_SOURCE_INACTIVITY = 'Inactivity';

export const GA_EVENT_ACTION_DOWNLOAD_APP = 'App download';
export const GA_EVENT_ACTION_MORE_INFO = 'More info';
export const GA_EVENT_CATEGORY_SUPPLEMENT_CLAIM = 'Supplement Claim';

export const GA_EVENT_LABEL_PAYMENT_PREFERENCES = 'PaymentPreferences';

export const GA_EVENT_ACTION_OPEN_FORM = 'Open Form';
export const GA_EVENT_ACTION_SUBMIT_FORM_SUCCESS = 'Submit Form - Success';
export const GA_EVENT_ACTION_SUBMIT_FORM_FAILED = 'Submit Form - Failed';

export const GA_EVENT_CATEGORY_CHEAP_FLIGHTS = 'Cheap flights';
export const GA_EVENT_ACTION_RETARGETING_ERROR = 'Retargeting error';
export const GA_EVENT_LABEL_NO_RETARGETING_BANNER_FOUND = `No banner found from users location`;

export const GA_BANNER_TYPE_CHEAP_FLIGHTS = 'cheap_flights';
export const GA_BANNER_TYPE_CHEAP_FLIGHTS_RETARGETING = 'cheap_flights_retargeting';

export const GA_EVENT_CATEGORY_WDC_MULTIPLE_MEMBERSHIPS = 'WDC multiple memberships';

export const GA_EVENT_CATEGORY_CHECK_IN = 'Check-in';
export const GA_EVENT_ACTION_CHECK_IN = 'check-in window';
export const GA_EVENT_LABEL_24H = '24h';
export const GA_EVENT_LABEL_48H = '48h';

export const GA_EVENT_CATEGORY_TEMPORARY_MESSAGE = 'Temporary Message';
export const GA_EVENT_ACTION_REDIRECT_TO_SEATS = 'redirect to seats';
export const GA_EVENT_ACTION_ADD = 'add';
export const GA_EVENT_LABEL_ALLOCATED_SEATS = 'Allocated Seats - Temporary';
export const GA_EVENT_LABEL_AUTO_CHECK_IN = 'Auto Check-in - Temporary';

export const GA_EVENT_LABEL_SELECTED_FARE_SHOW_ALL =
  'Selected flight and fare - show all';

export const GA_EVENT_LABEL_SHOW_ALL_BENEFITS = 'Show all benefits';

export const GA_EVENT_LABEL_RECENT_SEARCH = 'Recent search';

export const GA_EVENT_CATEGORY_AKAMAI_LOCATION_EVENT = 'akamai_location_event';
export const GA_ACTION_NO_USER_LOCATION_FOUND_IN_AKAMAI =
  'no_user_location_found_in_akamai';

export const GA_EVENT_CATEGORY_OUTBOUND_LINK = 'Outbound Link';
export const GA_EVENT_CATEGORY_MY_FLIGHTS = 'My Flights';
export const GA_EVENT_LABEL_ITINERARY = 'Itinerary';
export const GA_EVENT_ACTION_LOGIN = 'login';
export const GA_EVENT_ACTION_REMOVE_MODAL = 'remove modal';
export const GA_EVENT_ACTION_REMOVE = 'remove';
export const GA_EVENT_ACTION_CONTINUE = 'continue';
export const GA_EVENT_ACTION_VIEW_PRODUCT = 'View Product';
export const GA_EVENT_ACTION_READ_MORE = 'read more';
export const GA_EVENT_LABEL_ITINERARY_MODAL = 'Itinerary - Modal';
export const GA_EVENT_LABEL_CHECK_IN_MODAL = 'Checkin - Modal';
export const GA_EVENT_CATEGORY_EXIT_INTENT_MODAL = 'Exit Intent Modal';
export const GA_EVENT_ACTION_VIEW = 'view';
export const GA_EVENT_LABEL_SAVE_FLIGHT_ITINERARY = 'Save Flight - Itinerary';
export const GA_EVENT_LABEL_SAVE_FLIGHT_CHECK_IN = 'Save Flight - Checkin';

export const GA_EVENT_FIND_BOOKING = 'find_booking';
export const GA_EVENT_RESULT_SUCCESS = 'success';
export const GA_EVENT_RESULT_ERROR = 'error';

export const GA_EVENT_CATEGORY_SHOW_SEATMAP = 'Show Seatmap';

export const GA4_BANNER_CONTENT_E_SIM = 'esim';
export const GA4_BANNER_CONTENT_AIRPORT_PARKING = 'airport_parking';
export const GA4_BANNER_CONTENT_AIRPORT_TRANSFER = 'airport_transfer';
export const GA4_SEARCH_TYPE_FLIGHT_SELECT = 'flight_select';
export const GA4_DIRECTION_RETURN = 'return';
export const GA4_DIRECTION_ONE_WAY = 'one_way';

export const GA_EVENT_CATEGORY_MOBILE_BOTTOM_MENU = 'mobile_bottom_menu';

export const GA_EVENT_CATEGORY_CHECK_IN_COMPONENT = 'Check-in Component';
export const GA_EVENT_LABEL_ADD_AUTO_CHECK_IN_BUTTON = 'add-auto-check-in-button';

export const GA_EVENT_CATEGORY_PAYMENT = 'Payment';
export const GA_EVENT_ACTION_CORPORATE_CARD_CHECK = 'Corporate card check';
export const GA_EVENT_ACTION_CC_FEE_MODAL = 'CC Fee Modal';
export const GA_EVENT_LABEL_CORPORATE = 'Corporate';
export const GA_EVENT_LABEL_NON_CORPORATE = 'Non-corporate';
export const GA_EVENT_LABEL_CANCEL = 'Cancel';
export const GA_EVENT_LABEL_PAY = 'Pay';

export const GTM_PARAM_NOT_SET = '(not_set)';
export const GTM_EVENT_ACTION_CLICK = 'click';

export const GTM_EVENT_NAME_PAYMENT_OPTIONS = 'payment_options';
export const GTM_EVENT_LOCATION_BOOKING_PAYMENT = 'booking_payment';
export const GTM_EVENT_LOCATION_CORPORATE_CARD_MODAL = 'corporate_card_modal';
export const GTM_EVENT_CONTENT_CORPORATE_CARD = 'corporate_card';
export const GTM_EVENT_CONTENT_NON_CORPORATE_CARD = 'non_corporate_card';
export const GTM_EVENT_CLICK_ITEM_CANCEL = 'cancel';
export const GTM_EVENT_CLICK_ITEM_PAY = 'pay';

export const GTM_EVENT_CLAIMS_THANK_YOU_PAGE = 'Claims ThankYou';

export const GTM_EVENT_NAME_SMART_SEARCH = 'smart_search';
export const GTM_EVENT_NAME_SMART_SEARCH_START_BOOKING = 'smart_search_start_booking';
export const GTM_EVENT_NAME_SMART_SEARCH_BANNERS = 'smart_search_banners';
export const GTM_EVENT_NAME_PRODUCT_ACTION = 'product_action';
export const GTM_EVENT_SEARCH_TYPE_SMART_SEARCH = 'smart-search';
export const GTM_EVENT_LOCATION_SMART_SEARCH = 'smart_search';
export const GTM_EVENT_SMART_SEARCH_CONTENT_PROMO_FILTER = 'promo_filter';
export const GTM_EVENT_SMART_SEARCH_CONTENT_WDC = 'wdc';
export const GTM_EVENT_SMART_SEARCH_OFFER_TAG_PROMOTION = 'promotion';
export const GTM_EVENT_SMART_SEARCH_OFFER_TAG_NEW = 'new';
export const GTM_EVENT_SMART_SEARCH_CLICK_ITEM_TIMETABLE = 'show_departure_times';
export const GTM_EVENT_SMART_SEARCH_FLIGHT_TYPE_ONE_WAY = 'one_way';
export const GTM_EVENT_SMART_SEARCH_FLIGHT_TYPE_RETURN = 'return';

export const GTM_EVENT_NAME_PRICE_ALERT = 'price_alert';
export const GTM_EVENT_PRICE_ALERT_ACTION_ADD = 'add';
export const GTM_EVENT_PRICE_ALERT_ACTION_DELETE = 'delete';

export const GTM_EVENT_LOCATION_PROFILE = 'profile';

export const GTM_EVENT_NAME_ORIGIN_STATION_SEARCH = 'origin_station_search';
export const GTM_EVENT_NAME_DESTINATION_STATION_SEARCH = 'destination_station_search';
