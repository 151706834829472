import {
  CUSTOMER_TYPE,
  WDC_MEMBERSHIP_STANDARD,
  WDC_MEMBERSHIP_COBRANDED_STANDARD,
  WDC_MEMBERSHIP_COBRANDED,
  WDC_MEMBERSHIP_GROUP,
  STATE_ACTIVE,
  WDC_MEMBERSHIP_STANDARD_PLUS,
  WDC_MEMBERSHIP_PREMIUM,
  WDC_MEMBERSHIP_PREMIUM_PLUS,
  ACCESSIBILITY_READONLY,
} from '~/constants';
import map from '~/utils/fp/map';
import path from '~/utils/fp/path';
import curry from '~/utils/fp/curry';
import pathOr from '~/utils/fp/path-or';
import propEqTrue from '~/utils/fp/prop-eq-true';
import propEq from '~/utils/fp/prop-eq';
import prop from '~/utils/fp/prop';
import compose from '~/utils/fp/compose';
import length from '~/utils/fp/length';
import head from '~/utils/fp/head';
import gt from '~/utils/fp/gt';
import __ from '~/utils/fp/__';
import complement from '~/utils/fp/complement';
import either from '~/utils/fp/either';
import equals from '~/utils/fp/equals';
import toLowerCase from '~/utils/fp/to-lower-case';
import converge from '~/utils/fp/converge';
import concat from '~/utils/fp/concat';
import isNotEmpty from '~/utils/object/is-not-empty';
import isNotNil from '~/utils/object/is-not-nil';
import isEmpty from '~/utils/object/is-empty';
import abs from '~/utils/fp/abs';
import {
  currentDateAndTime,
  differenceInMonths,
  differenceInYears,
  endOfDay,
  isAfter,
  isBefore,
} from '~/utils/date';
import anyPass from '~/utils/fp/any-pass';
import * as searchGetters from '~/store/modules/search/getters';
import * as resourcesGetters from '../resources/getters';

const userProp = curry((prop, state) => path(prop, state.user));

const userVolatileProp = curry((_path, state) => path(_path, state.user.volatile));

const userPropWithFallback = curry((fallbackValue, prop, state) =>
  pathOr(fallbackValue, `user.${prop}`, state)
);

/**
 * @type {(state: State) => string}
 */
export const userMobilePhone = userProp('profile.mobilePhone');

/**
 * @type {(state: State) => Promise | undefined}
 */
export const captchaRequiredPromise = userVolatileProp('captchaRequiredDeferred.promise');

/**
 * @type {(state: State) => Promise | undefined}
 */
export const hasCaptchaRequiredDeferred = compose(
  isNotNil,
  userVolatileProp('captchaRequiredDeferred')
);

/**
 * @type {(state: State) => boolean}
 */
export const isCaptchaModalVisible = userVolatileProp('isCaptchaModalVisible');

/**
 * @type {(state: State) => boolean}
 */
export const isYouAreDoneThankYouModalVisible = userVolatileProp(
  'isYouAreDoneThankYouModalVisible'
);

/**
 *
 * @type {(state: State) => boolean}
 */
export const isLatestGCCAccepted = userProp('isLatestGCCAccepted');

/**
 *
 * @type {(state: State) => boolean}
 */
export const isLatestWizzAccountPolicyAccepted = userProp(
  'isLatestWizzAccountPolicyAccepted'
);

/**
 * @type {(state: State) => string}
 */
export const wdcExpirationDate = userProp('profile.wdcExpirationDate');

/**
 * @type {(state: State) => string}
 */
export const profileFirstName = userProp('profile.firstName.value');

/**
 * @type {(state: State) => string}
 */
export const profileFirstNameAccessibility = userProp('profile.firstName.accessibility');

/**
 * @type {(state: State) => boolean}
 */
export const isProfileFirstNameReadonly = compose(
  equals(ACCESSIBILITY_READONLY),
  profileFirstNameAccessibility
);

/**
 * @type {(state: State) => string}
 */
export const profileLastName = userProp('profile.lastName.value');

/**
 * @type {(state: State) => string}
 */
export const profileLastNameAccessibility = userProp('profile.lastName.accessibility');

/**
 * @type {(state: State) => boolean}
 */
export const isProfileLastNameReadonly = compose(
  equals(ACCESSIBILITY_READONLY),
  profileLastNameAccessibility
);

/**
 * @type {(state: State) => boolean}
 */
export const isPrivacyPolicyAccepted = userProp('profile.ppAccepted');

/**
 * @type {(state: State) => boolean}
 */
export const shouldShowNewsletterConsentModal = userProp(
  'profile.shouldShowNewsletterConsentModal'
);

/**
 * @type {(state: State) => boolean}
 */
export const isLoggedIn = userProp('isLoggedIn');

/**
 * @type {(state: State) => boolean}
 */
export const isNotLoggedIn = complement(isLoggedIn);

/**
 * @type {(state: State) => string}
 */
export const nationality = userPropWithFallback('', 'profile.nationality');

/**
 * @type {(state: State) => string}
 */
export const userAddress = userPropWithFallback('', 'profile.address');

/**
 * @type {(state: State) => string}
 */
export const residenceCountryCode = userPropWithFallback('', 'profile.address.country');

export const profile = userProp('profile');

export const hasPrivilegePass = compose(
  equals(STATE_ACTIVE),
  toLowerCase,
  userPropWithFallback('', 'profile.privilegePass.state')
);

export const userHasEmailSubscription = userProp('profile.hasUserEmailSubscription');

export const isAgency = (state) => customerType(state) === CUSTOMER_TYPE.AGENCY;

export const isNotAgency = complement(isAgency);

export const customerType = userProp('profile.customerType');

export const profileAddress = userProp('profile.address');

export const currentBookings = userProp('profile.currentBookings');

export const pastBookings = userProp('profile.pastBookings');

export const cancelledBookings = userProp('profile.cancelledBookings');

// Notes: the only useful info we have about these flights are the PNR's
export const refundableBookingPnrs = compose(
  map(prop('recordLocator')),
  userProp('profile.refundableBookings')
);

export const hasAnyRefundableBooking = compose(
  isNotEmpty,
  userProp('profile.refundableBookings')
);

export const automaticallyRefundableBookingPnrs = compose(
  map(prop('recordLocator')),
  userProp('profile.automaticallyRefundableBookings')
);

export const hasAnyAutomaticallyRefundableBooking = compose(
  isNotEmpty,
  userProp('profile.automaticallyRefundableBookings')
);

export const hasMoreThanThreeCurrentBookingsInTotal = (state) =>
  state.user?.profile?.currentBookingsCountInTotal > 3;

export const hasMoreThanThreePastBookingsInTotal = (state) =>
  state.user?.profile?.pastBookingsCountInTotal > 3;

export const hasMoreThanThreeCancelledBookingsInTotal = (state) =>
  state.user?.profile?.cancelledBookingsCountInTotal > 3;

export const targetedWdcOffer = userProp('targetedWdcOffer');

export const userDateOfBirth = userProp('profile.dateOfBirth');

export const userAgeInYears = (state) =>
  abs(differenceInYears(userDateOfBirth(state), currentDateAndTime()));

export const originalProfileState = userProp('originalProfileState');

export const originalProfileFirstName = userProp('originalProfileState.firstName.value');

export const memberNumber = userPropWithFallback('', 'profile.memberNumber');

/**
 * @type {(state: State) => Boolean}
 */
export const isWdcPremiumEnabled = userPropWithFallback(
  false,
  'profile.isWdcPremiumEnabled'
);

export const webUserId = userProp('profile.webUserId');

export const userEmail = userProp('profile.email');

export const isInvalidBookingStateModalVisible = userProp(
  'isInvalidBookingStateModalVisible'
);

export const isLoading = compose(gt(__, 0), userProp('loadingCounter'));

export const isCaptchaRequiredForLogin = userProp('login.isCaptchaRequired');

export const loginErrors = userProp('login.errors');

export const hasLoginError = compose(isNotEmpty, loginErrors);

export const profileErrors = userProp('profile.errors');

export const hasProfileError = compose(isNotEmpty, profileErrors);

export const registrationErrors = userProp('registration.errors');

export const hasRegistrationError = compose(isNotEmpty, registrationErrors);

/**
 * @type {(state: State) => string[]}
 */
export const loginAndProfileErrors = converge(concat, [loginErrors, profileErrors]);

export const hasLoginOrProfileError = either(hasLoginError, hasProfileError);

/**
 * @type {(state: State) => string[]}
 */
export const registrationAndProfileErrors = converge(concat, [
  registrationErrors,
  profileErrors,
]);

export const hasRegistrationOrProfileError = either(
  hasRegistrationError,
  hasProfileError
);

export const shouldDisplayMobilePhoneWarningModal = userProp(
  'shouldDisplayMobilePhoneWarningModal'
);

/**
 * @type {(state: State) => string}
 */
export const lastSeenActive = userProp('lastSeenActive');

export const feedbackQuiz = userProp('feedback.labels');

export const feedbackErrors = userProp('feedback.errors');

export const feedbackRating = userProp('feedback.rating');

export const geoLocation = userProp('geoLocation');

export const isFakeGeoLocationAdded = userProp('isFakeGeoLocationAdded');

export const previousFirstName = userProp('previousFirstName');

/**
 * @type {(state: State) => string}
 */
export const detectedBrowserLocation = userPropWithFallback(
  '',
  'detectedBrowserLocation'
);

export const accountHistoryDeposits = userProp('accountHistory.deposits');

export const accountHistoryWithDrawal = userProp('accountHistory.withDrawal');

export const accountHistoryRemaining = userProp('accountHistory.remaining');

export const hasAccountHistoryData = (state) => {
  return anyPass(
    isNotEmpty(accountHistoryDeposits(state)),
    isNotEmpty(accountHistoryWithDrawal(state)),
    accountHistoryRemaining(state).amount > 0
  );
};

/**
 * @type {(state: State) => Boolean}
 */
export const isBrowsingFromEU = (state) => {
  if (isEmpty(detectedBrowserLocation(state))) return false;
  const originCountry = resourcesGetters
    .countries(state)
    .find(
      (country) =>
        country.code.toLowerCase() === detectedBrowserLocation(state).toLowerCase()
    );

  return originCountry?.isEu ?? false;
};

/**
 * @type {(state: State) => Boolean}
 */
export const isEUCitizen = (state) => {
  if (!isLoggedIn(state) || isEmpty(nationality(state))) return false;
  const userCountry = resourcesGetters
    .countries(state)
    .find((country) => country.code.toLowerCase() === nationality(state).toLowerCase());

  return userCountry?.isEu ?? false;
};

export const selectedWdcMembership = userProp('profile.selectedWdcMembership');

/**
 * @type {(state: State) => String[]}
 */
export const wdcMemberships = (state) =>
  (state.user?.profile?.wdcMemberships || []).map((membership) => ({
    ...membership,
    isTypeStandard: isWdcMembershipTypeStandard(membership.membership),
    isActive: isWdcActive(membership.expirationDate),
    isExpiresSoon: isWdcMembershipExpiresSoon(membership.expirationDate),
    isExpired: isWdcMembershipExpired(membership.expirationDate),
  }));

const isWdcActive = (expirationDate) =>
  expirationDate ? isBefore(currentDateAndTime(), endOfDay(expirationDate)) : false;

const isWdcMembershipExpiresSoon = (expirationDate) =>
  expirationDate
    ? isWdcActive(expirationDate) &&
      differenceInMonths(expirationDate, currentDateAndTime()) < 1
    : false;

const isWdcMembershipExpired = (expirationDate) =>
  expirationDate ? isAfter(currentDateAndTime(), endOfDay(expirationDate)) : false;

const isWdcMembershipTypeStandard = (membership) =>
  (membership || '').includes('STANDARD');

/**
 * @type {(state: State) => boolean}
 */
export const hasStandardWdcMembership = (state) =>
  wdcMemberships(state).some(({ membership }) =>
    [WDC_MEMBERSHIP_STANDARD, WDC_MEMBERSHIP_COBRANDED_STANDARD].includes(membership)
  );

/**
 * @type {(state: State) => boolean}
 */
export const hasStandardCobrandedWdcMembership = (state) =>
  isNotEmpty(
    wdcMemberships(state).find(propEq('membership', WDC_MEMBERSHIP_COBRANDED_STANDARD))
  );

/**
 * @type {(state: State) => boolean}
 */
export const hasGroupWdcMembership = (state) =>
  wdcMemberships(state).some(({ membership }) =>
    [WDC_MEMBERSHIP_COBRANDED, WDC_MEMBERSHIP_GROUP].includes(membership)
  );

/**
 * @type {(state: State) => boolean}
 */
export const hasCoBrandedGroupWdcMembership = (state) =>
  isNotEmpty(wdcMemberships(state).find(propEq('membership', WDC_MEMBERSHIP_COBRANDED)));

/**
 * @type {(state: State) => boolean}
 */
export const hasAnyStandardOrStandardPlusWdcMembership = (state) =>
  hasStandardWdcMembership(state) || hasGroupWdcMembership(state);

/**
 * @type {(state: State) => boolean}
 */
export const hasPremiumWdcMembership = (state) =>
  isNotEmpty(wdcMemberships(state).find(propEq('membership', WDC_MEMBERSHIP_PREMIUM)));

/**
 * @type {(state: State) => boolean}
 */
export const hasAnyPremiumWdcMembership = (state) =>
  wdcMemberships(state).some(({ membership }) =>
    [WDC_MEMBERSHIP_PREMIUM, WDC_MEMBERSHIP_PREMIUM_PLUS].includes(membership)
  );

/**
 * @type {(state: State) => boolean}
 */
export const hasStandardPlusWdcMembership = (state) =>
  isNotEmpty(
    wdcMemberships(state).find(propEq('membership', WDC_MEMBERSHIP_STANDARD_PLUS))
  );

/**
 * @type {(state: State) => boolean}
 */
export const hasPremiumPlusWdcMembership = (state) =>
  isNotEmpty(
    wdcMemberships(state).find(propEq('membership', WDC_MEMBERSHIP_PREMIUM_PLUS))
  );

/**
 * @type {(state: State) => boolean}
 */
export const userWdcType = userProp('profile.userWdcType');

/**
 * @type {(state: State) => boolean}
 */
export const hasFreeWdc = userProp('profile.hasFreeWdc');

/**
 * @type {(state: State) => boolean}
 */
export const hasOnlyWdcLight = (state) =>
  hasFreeWdc(state) && !hasAnyWdcMembership(state);

/**
 * @type {(state: State) => boolean}
 */
export const hasStandardItalyMembership = (state) =>
  isNotEmpty(wdcMemberships(state).find(propEq('membership', 'WDDC_STANDARD_ITALY')));

/**
 * @type {(state: State) => boolean}
 */
export const hasGroupItalyMembership = (state) =>
  isNotEmpty(wdcMemberships(state).find(propEq('membership', 'WDDC_GROUP_ITALY')));

/**
 * @type {(state: State) => boolean}
 */
export const hasAnyWdcMembership = (state) => activeWdcMemberships(state).length > 0;

/**
 * @type {(state: State) => boolean}
 */
export const hasAnyWdcMembershipOrWdcLight = (state) =>
  activeWdcMemberships(state).length > 0 || hasFreeWdc(state);

/**
 * @type {(state: State) => boolean}
 */
export const hasMultipleMemberships = (state) => activeWdcMemberships(state).length > 1;

/**
 * @type {(state: State) => boolean}
 */
export const hasAnyStandardWdcMembership = either(
  hasStandardWdcMembership,
  hasStandardItalyMembership
);

/**
 * @type {(state: State) => boolean}
 */
export const hasAnyGroupWdcMembership = either(
  hasGroupWdcMembership,
  hasGroupItalyMembership
);

/**
 * @type {(state: State) => boolean}
 */
export const hasAnyDomesticMembership = (state) =>
  hasStandardItalyMembership(state) || hasGroupItalyMembership(state);

/**
 * @type {(state: State) => boolean}
 */
export const isWdcMembershipNotUpgradable = (state) => {
  return (
    !hasFreeWdc(state) ||
    !isLoggedIn(state) ||
    (!isWdcPremiumEnabled(state) &&
      hasStandardWdcMembership(state) &&
      !hasStandardItalyMembership(state) &&
      searchGetters.isDomestic(state)) ||
    (!isWdcPremiumEnabled(state) &&
      hasStandardItalyMembership(state) &&
      !hasStandardWdcMembership(state) &&
      !searchGetters.isDomestic(state)) ||
    (!isWdcPremiumEnabled(state) &&
      hasGroupWdcMembership(state) &&
      !hasGroupItalyMembership(state) &&
      searchGetters.isDomestic(state)) ||
    (!isWdcPremiumEnabled(state) &&
      hasGroupItalyMembership(state) &&
      !hasGroupWdcMembership(state) &&
      !searchGetters.isDomestic(state))
  );
};

export const activeWdcMemberships = (state) =>
  wdcMemberships(state).filter(propEqTrue('isActive'));

/**
 * @type {Record<string, unknown>[]}
 */
export const userWdcMemberships = (state) => {
  if (isEmpty(wdcMemberships(state))) return [];
  return equals(length(activeWdcMemberships(state)), 0) &&
    gt(length(wdcMemberships(state)))
    ? [head(wdcMemberships(state))]
    : activeWdcMemberships(state);
};

export const isYourClaimsPopupClicked = userProp('isYourClaimsPopupClicked');

/**
 * @type {(state: State) => boolean}
 */
export const isSuccessfulSubscriptionVisible = userProp(
  'profile.isSuccessfulSubscriptionVisible'
);

export const isEmailVerificationSuccessful = userProp('isEmailVerified');

export const isContactDataVerificationMessageVisible = userProp(
  'contactDataVerificationMessageVisible'
);

export const hasAllYouCanFlightOrMultiPassProgram = userProp(
  'profile.hasAllYouCanFlightOrMultiPassProgramme'
);
