import { FALLBACK_CURRENCY_CODE } from '~/constants';

export const moduleName = 'user';

export const getDefaultState = () => ({
  // an object with longitude and latitude, put here by top level app.js logic
  geoLocation: null,
  detectedBrowserLocation: '',
  // flight search page (?) (the ewizz landing page) may override it from the url
  // technically the flight search page is in schizophrenic state: it's somewhere
  // between a standalone component and a page handler
  isFakeGeoLocationAdded: false,
  isLatestGCCAccepted: true,
  isLatestWizzAccountPolicyAccepted: true,
  isLoggedIn: false,
  isInvalidBookingStateModalVisible: false,
  wdc: false,
  targetedWdcOffer: null,
  isYourClaimsPopupClicked: false,
  previousFirstName: '',

  login: {
    errors: [],
    isCaptchaRequired: false,
  },

  registration: {
    errors: [],
  },

  profile: getProfileModel(),
  originalProfileState: {},
  lastSeenActive: null,
  loadingCounter: 0,
  feedback: {
    flowType: '',
    labels: {},
    errors: [],
    rating: {},
  },
  shouldDisplayMobilePhoneWarningModal: false,
  volatile: getVolatileModel(),
  accountHistory: getWizzAccountModel(),
  isEmailVerified: false,
  contactDataVerificationMessageVisible: false,
});

export const getWizzAccountModel = () => ({
  deposits: [],
  remaining: {
    amount: 0,
    currencyCode: FALLBACK_CURRENCY_CODE,
  },
  withDrawal: [],
});

const getVolatileModel = () => ({
  // note: these are won't be synced to session storage on change
  isCaptchaModalVisible: false,
  isYouAreDoneThankYouModalVisible: false,
  captchaRequiredDeferred: null,
});

export const getPriceModel = () => ({
  amount: 0,
  currencyCode: null,
});

export const getProfileModel = () => ({
  customerType: 'regular',

  address: {
    address: '',
    city: '',
    zip: '',
    country: '',
  },

  workPhone: '',

  invoiceDetails: {
    companyName: '',
    taxNumber: '',
  },

  dateOfBirth: null,
  email: null,

  firstName: {
    value: null,
    accessibility: null,
  },

  gender: {
    value: null,
    accessibility: null,
  },
  hasFreeWdc: false,
  isWdcPremiumEnabled: false,
  isSuccessfulSubscriptionVisible: false,
  hasUserEmailSubscription: false,
  shouldShowNewsletterConsentModal: false, // -> newsletter-consent-modal

  // note: don't try to rename this to isPrivacyPolicyAccepted for example, login
  //  response will overwrite this profile object so the renamed prop won't be
  //  updated by itself
  ppAccepted: false,
  homePhone: null,

  lastName: {
    value: null,
    accessibility: null,
  },

  memberNumber: null,
  minimumBaggageDiscount: getPriceModel(),
  minimumFareAmount: getPriceModel(),
  minimumFareDiscount: getPriceModel(),
  mobilePhone: null,
  nationality: null,
  wdcExpirationDate: null,
  currentBookings: [],
  currentBookingsCountInTotal: 0,
  pastBookings: [],
  pastBookingsCountInTotal: 0,
  cancelledBookings: [],
  cancelledBookingsCountInTotal: 0,
  refundableBookings: [],
  automaticallyRefundableBookings: [],
  errors: [],
  isMobilePhoneValid: true,
  webUserId: null,

  privilegePass: {
    expirationDate: null,
    state: null,
  },

  wdcMemberships: [],
  selectedWdcMembership: {},
  hasAllYouCanFlightOrMultiPassProgramme: false,
});
