import {
  BUNDLE_BASIC,
  BUNDLE_SMART,
  BUNDLE_MIDDLE,
  BUNDLE_MIDDLE_TWO,
  BUNDLE_PLUS,
} from '~/constants';

export const moduleName = 'feature';

export const fetchFeaturesStates = Object.freeze({
  INITIAL: 'initial',
  PENDING: 'pending',
  RESOLVED: 'resolved',
});

export const FEATURE_NAME_LABEL_03 = 'Label03';
export const FEATURE_NAME_LABEL_04 = 'Label04';
export const FEATURE_NAME_LABEL_05 = 'Label05';
export const FEATURE_NAME_LABEL_06 = 'Label06';
export const FEATURE_NAME_LABEL_07 = 'Label07';
export const FEATURE_NAME_LABEL_09 = 'Label09';

export const FEATURE_NAME_SEAT_MAP_UPSELL = 'SeatMapSummaryText';
export const FEATURE_NAME_NO_CHECKED_IN_BAG_LABEL_TEST = 'Label08';
export const FEATURE_NAME_ITINERARY_NEW_BOOKING_COM_MODAL_VISIBILITY =
  'ItineraryNewBookingComModalVisibility';
export const FEATURE_NAME_URGENCY_MESSAGE_VISIBILITY = 'UrgencyMessageVisibility';
export const FEATURE_NAME_SEAT_RECOMMENDATION = 'SeatRecommendation';
export const FEATURE_NAME_PAYMENT_EXIT_INTENT_MODAL = 'PaymentExitIntentModal';
export const FEATURE_NAME_GOOGLE_ADS_SUCCESSFUL_PAYMENT = 'GoogleAdsSuccessfulPayment';
export const FEATURE_NAME_FLIGHT_SELECT_BUNDLE_ORDER = 'BundleOrderChange';
export const FEATURE_NAME_GOOGLE_ADS_SUCCESSFUL_CHECK_IN = 'GoogleAdsSuccessfulCheckIn';
export const FEATURE_NAME_SEATING_TOGETHER = '56393SittingTogether';
export const FEATURE_NAME_PROFILE_YOUR_CLAIMS = '88293PaxMyClaims';
export const FEATURE_NAME_RENTAL_CARS_VISIBILITY = 'RentalCars';
export const FEATURE_NAME_CONTACT_DATA_VERSION = 'contactDataVersion';
export const FEATURE_NAME_CHATBOT_AUDIENCE_DISTRIBUTION =
  '83944ChatbotAudienceDistribution';
export const FEATURE_NAME_CHATBOT_PLACEHOLDER = 'ChatbotPlaceholder';
export const FEATURE_NAME_CHATBOT_MAIN_PAGE = '97097ChatbotMainPage';
export const FEATURE_NAME_FARE_PRICING = 'FarePricing';
export const FEATURE_NAME_EVERYMUNDO_PIXEL_FLIGHT_SEARCH = 'EverymundoPixelFlightSearch';
export const FEATURE_NAME_EVERYMUNDO_PIXEL_SUCCESSFUL_BOOKING =
  'EverymundoPixelSuccessfulBooking';
export const FEATURE_NAME_STICKY_BASKET_WITHOUT_SIDEBAR = 'StickyBasketWithoutSidebar';
export const FEATURE_NAME_WDC_DISCOUNT_APPLIED_ON_BAGS = 'WdcDiscountAppliedOnBags';

export const FEATURE_NAME_LOGIN_AT_SERVICES_STEP = 'LoginAtServicesStep';

export const FEATURE_NAME_IS_TRAVEL_PLANNING_MAP_SEARCH_COUNT_TRACKING =
  'TravelPlanningMapSearchCountTracking';

export const FEATURE_NAME_NEW_COOKIE_POLICY = 'NewCookiePolicy';

export const FEATURE_NAME_PRM_SERVICE_ADDITION = 'PrmServiceAddition';

export const FEATURE_VALUE_IS_SEAT_PRICE_GROUP_VISIBLE =
  'seat_price_group_visualization_visible';

export const SEAT_MAP_UPSELL_DEFAULT_VARIATION = 'seat_map_with_summary';

export const PASSENGER_INNER_CONTENT_WITH_CHECKBOX = 'new_with_checkbox';

export const BOOKING_FLOW_STEPS_VISIBLE = 'booking_flow_steps_visible';

export const SEAT_RECOMMENDATION_ENABLED = 'A_enabled';

export const ITINERARY_NEW_BOOKING_COM_MODAL_VISIBLE = 'B_visible';

export const BOOKING_COM_PROMO_ENABLED = 'search_with_hotel_ribbon';

export const PAYMENT_EXIT_INTENT_MODAL_A = 'A_disabled';

export const PAYMENT_EXIT_INTENT_MODAL_B = 'B_secure_price';

export const PAYMENT_EXIT_INTENT_MODAL_C = 'C_avoid_high_price';

export const FEATURE_VALUE_SEATING_TOGETHER_ACTIVE = 'On';

export const GOOGLE_ADS_ENABLED = 'B_enabled';

const BUNDLE_ORDER_A = 'A_default';
const BUNDLE_ORDER_B = 'B_default';
const BUNDLE_ORDER_C = 'C_new';
const BUNDLE_ORDER_D = 'D_new';
export const BUNDLE_ORDER_DEFAULT = BUNDLE_ORDER_A;

export const FLIGHT_SELECT_BUNDLE_ORDER = new Map()
  .set(BUNDLE_ORDER_A, {
    [BUNDLE_BASIC]: 3,
    [BUNDLE_SMART]: 2,
    [BUNDLE_MIDDLE_TWO]: 1,
    [BUNDLE_MIDDLE]: 1,
    [BUNDLE_PLUS]: 0,
  })
  .set(BUNDLE_ORDER_B, {
    [BUNDLE_BASIC]: 3,
    [BUNDLE_SMART]: 2,
    [BUNDLE_MIDDLE_TWO]: 1,
    [BUNDLE_MIDDLE]: 1,
    [BUNDLE_PLUS]: 0,
  })
  .set(BUNDLE_ORDER_C, {
    [BUNDLE_BASIC]: 0,
    [BUNDLE_SMART]: 1,
    [BUNDLE_MIDDLE_TWO]: 2,
    [BUNDLE_MIDDLE]: 2,
    [BUNDLE_PLUS]: 3,
  })
  .set(BUNDLE_ORDER_D, {
    [BUNDLE_BASIC]: 3,
    [BUNDLE_SMART]: 0,
    [BUNDLE_MIDDLE_TWO]: 1,
    [BUNDLE_MIDDLE]: 1,
    [BUNDLE_PLUS]: 2,
  });

export const CONTACT_DATA_VERSION_A = 'A_allow_bulk';

export const CONTACT_DATA_VERSION_B = 'B_force_fill_data';

export const FEATURE_VALUE_PROFILE_YOUR_CLAIMS = 'On';

export const FEATURE_VALUE_CHATBOT_AUDIENCE_DISTRIBUTION = 'On';

export const FEATURE_VALUE_CHATBOT_PLACEHOLDER = 'On';

export const FEATURE_VALUE_CHATBOT_MAIN_PAGE = 'On';

const FARE_PRICING_VARIANT_A = 'A';
const FARE_PRICING_VARIANT_B = 'B';
const FARE_PRICING_VARIANT_C = 'C';

export const FARE_PRICING_VARIANTS = [
  FARE_PRICING_VARIANT_A,
  FARE_PRICING_VARIANT_B,
  FARE_PRICING_VARIANT_C,
];

export const FEATURE_VALUE_EVERYMUNDO_PIXEL_FLIGHT_SEARCH = 'On';
export const FEATURE_VALUE_EVERYMUNDO_PIXEL_SUCCESSFUL_BOOKING = 'On';

export const FEATURE_VALUE_STICKY_BASKET_WITHOUT_SIDEBAR_ENABLED = 'B_new';

export const FEATURE_VALUE_WDC_DISCOUNT_APPLIED_ON_BAGS_ENABLED = 'B_new';

export const FEATURE_VALUE_TRAVEL_PLANNING_MAP_SEARCH_COUNT_TRACKING = 'On';

export const FEATURE_VALUE_NEW_COOKIE_POLICY_ENABLED = 'B_new';

export const FEATURE_VALUE_PRM_SERVICE_ADDITION_ENABLED = 'B_new';

export const FEATURE_VALUE_SEAT_PANEL_SELECTION_REQUIRED = 'B_required';

export const FEATURE_NAME_HERO_BANNER_VARIANTS = '80796-HeroBannerPromo';
const FEATURE_VALUE_HERO_BANNER_VARIANT_A = 'A_default';
const FEATURE_VALUE_HERO_BANNER_VARIANT_B = 'B_new';

export const FEATURE_VALUE_HERO_BANNER_VARIANTS = [
  FEATURE_VALUE_HERO_BANNER_VARIANT_A,
  FEATURE_VALUE_HERO_BANNER_VARIANT_B,
];

export const FEATURE_NAME_AIRSHIP_WEB_PUSH_ENABLER = 'AirshipWebPushEnabler';
export const FEATURE_VALUE_AIRSHIP_WEB_PUSH_ENABLER = 'On';

export const FEATURE_NAME_CHECKED_IN_BAGGAGE_26_KG = 'CheckedinBaggage26kg';
export const FEATURE_VALUE_CHECKED_IN_BAGGAGE_26_KG_ENABLED = 'B_enabled';

export const FEATURE_VALUE_LOGIN_AT_SERVICES_STEP_ENABLED = 'B_new';

export const FEATURE_NAME_TILE_SERVICES = 'TileServices';
export const FEATURE_VALUE_TILE_SERVICES_ENABLED = 'B_new';

export const FEATURE_NAME_SMART_BUNDLE = 'SmartBundle';
export const FEATURE_VALUE_SMART_BUNDLE_ENABLED = 'C_new';

export const FEATURE_NAME_POPULAR_TAG_ON_GO_BUNDLE = 'PopularTagOnGoBundle';
export const FEATURE_VALUE_POPULAR_TAG_ON_GO_BUNDLE_ENABLED = 'C_new';

export const FEATURE_NAME_PAY_BY_LINK_NAME_CHANGE = 'PayByLinkNameChange';
export const FEATURE_VALUE_PAY_BY_LINK_NAME_CHANGE_ENABLED = 'C_new';

export const FEATURE_NAME_SITTING_TOGETHER_ACCORDION = 'SittingTogetherAccordion';
export const FEATURE_VALUE_SITTING_TOGETHER_ACCORDION_ENABLED = 'B_accordion';

export const FEATURE_NAME_SERVICE_LISTS_HIGHLIGHTS = 'ServiceListsHighlights';
export const FEATURE_VALUE_SERVICE_LISTS_HIGHLIGHTS_ENABLED = 'B_highlighted';

export const FEATURE_NAME_AIRSHIP_NOTIFICATION_DELAY = '127856_AirshipNotificationDelay';
export const AIRSHIP_NOTIFICATION_DELAY_DEFAULT_VARIATION = '20';

export const FEATURE_NAME_AIRSHIP_NOTIFICATION_MOBILE_DELAY =
  '133667_AirshipNotificationMobileDelay';
export const AIRSHIP_NOTIFICATION_MOBILE_DELAY_DEFAULT_VARIATION = '5';

export const FEATURE_NAME_CHEAP_FLIGHT_TRACKING_ENABLER =
  '127732CheapFlightTrackingEnabler';
export const FEATURE_VALUE_CHEAP_FLIGHT_TRACKING_ENABLER = 'On';

export const FEATURE_NAME_HOTJAR_ENABLER = '129396HotjarTrackingEnabler';
export const FEATURE_VALUE_HOTJAR_ENABLER = 'On';

export const FEATURE_NAME_INFORMATION_MENU_PLACEMENT = '129397InfoMenuPlacement';
export const INFORMATION_MENU_PLACEMENT_A_DEFAULT = 'A_default';
export const INFORMATION_MENU_PLACEMENT_B_DEFAULT_TRACKING = 'B_default_with_tracking';
export const INFORMATION_MENU_PLACEMENT_C_NEW_TRACKING = 'C_new_with_tracking';
export const INFORMATION_MENU_PLACEMENT_D_BOTH_TRACKING = 'D_both_with_tracking';

export const FEATURE_NAME_WDC_LIGHT = 'WdcLight';
export const FEATURE_VALUE_WDC_LIGHT_ENABLED = 'On';

export const FEATURE_NAME_E_SIM_DATA_ROAMING = 'US133788eSimDataRoaming';
export const FEATURE_VALUE_E_SIM_DATA_ROAMING_ENABLED = 'On';

export const FEATURE_NAME_CHECK_IN_TEMPORARY_MESSAGE =
  '141234_ReduceCheckInTemporaryMessage';
export const FEATURE_VALUE_CHECK_IN_TEMPORARY_MESSAGE_ENABLED = 'On';

export const FEATURE_NAME_INSURANCE_PRICING = 'InsurancePricing';
export const FEATURE_VALUE_INSURANCE_PRICING_ENABLED = 'C_new';

export const FEATURE_NAME_RECAPTCHA_ON_REGISTRATION = 'RecaptchaOnRegistration';
export const FEATURE_VALUE_RECAPTCHA_ON_REGISTRATION_ENABLED = 'B_enabled';

export const FEATURE_NAME_SUPPLEMENTAL_PAYMENT = '145812SupplementalPayment';
export const FEATURE_VALUE_SUPPLEMENTAL_PAYMENT_ENABLED = 'On';

export const FEATURE_NAME_BLUE_RIBBON_BAG_TRACKING = 'BlueRibbonBagTracking';
export const FEATURE_VALUE_BLUE_RIBBON_BAG_TRACKING_ENABLED = 'C_enabled';

export const FEATURE_NAME_MANDATORY_PRM_ON_BOOKING_FLOW = 'MandatoryPRM';
export const FEATURE_VALUE_MANDATORY_PRM_ON_BOOKING_FLOW_DISABLED = 'A_default';
export const FEATURE_VALUE_MANDATORY_PRM_ON_BOOKING_FLOW_ENABLED = 'B_new';

export const FEATURE_NAME_SMALL_BAGS = 'SmallBags';
export const FEATURE_VALUE_SMALL_BAGS_ENABLED = 'C_enabled';

export const FEATURE_NAME_HIGHLIGHTED_WIZZ_GO = 'HighlightedWizzGo';
export const FEATURE_VALUE_HIGHLIGHTED_WIZZ_GO_ENABLED = 'C_enabled';

export const FEATURE_NAME_CHOOSE_SEAT_PROMO_PICTURE = 'ChooseSeatPromoPicture';
export const FEATURE_VALUE_CHOOSE_SEAT_PROMO_PICTURE_ENABLED = 'C_enabled';

export const FEATURE_NAME_CHECK_IN_EARLY = '164879_CheckInEarly';
export const FEATURE_VALUE_CHECK_IN_EARLY_ENABLED = 'B_enabled';

export const FEATURE_NAME_COOKIE_POLICY_VARIANT = 'CookiePolicyVariant';
export const FEATURE_VALUE_WIZZ_DEFAULT = 'A_default';
export const FEATURE_VALUE_ONETRUST = 'B_onetrust';

export const FEATURE_NAME_BUNDLE_PRICE_COMPARED_TO_BASIC = 'BundlePriceComparedToBasic';
export const FEATURE_VALUE_BUNDLE_PRICE_COMPARED_TO_BASIC_ENABLED = 'C_new';

export const FEATURE_NAME_FLIGHT_PAST_PRICE = 'FlightPastPrice';
export const FEATURE_VALUE_FLIGHT_PAST_PRICE_ENABLED = 'C_new';

export const FEATURE_NAME_SHOW_SEATMAP_BUTTON = '171348_ShowSeatmapButton';
export const FEATURE_VALUE_SHOW_SEATMAP_BUTTON_NEW = 'B_new';

export const FEATURE_NAME_AIRPORT_CHECK_IN_COMPLIANCE_ON_FLIGHT_SELECT =
  'AirportCheckInComplianceOnFlightSelect';

export const FEATURE_VALUE_AIRPORT_CHECK_IN_COMPLIANCE_ON_FLIGHT_SELECT_ENABLED = 'C_new';

export const FEATURE_NAME_WIZZ_GO_PER_PERSON_FLIGHT = '172726_WizzGoPerPersonPerFlight';
export const FEATURE_VALUE_WIZZ_GO_PER_PERSON_FLIGHT_ENABLED = 'B_enabled';

export const FEATURE_NAME_SITTING_TOGETHER_PER_PERSON_FLIGHT =
  '159680_SittingTogetherPerPersonPerFlight';
export const FEATURE_VALUE_SITTING_TOGETHER_PER_PERSON_FLIGHT_ENABLED = 'B_enabled';

export const FEATURE_NAME_WIZZ_PRIORITY_BENEFITS_COPY = '174469_WIZZPriorityBenefitsCopy';
export const FEATURE_VALUE_WIZZ_PRIORITY_BENEFITS_COPY_VERSION_B = 'Version_B';
export const FEATURE_VALUE_WIZZ_PRIORITY_BENEFITS_COPY_VERSION_C = 'Version_C';

export const FEATURE_NAME_MOBILE_BOTTOM_MENU = '173418_MobileBottomMenu';
export const FEATURE_VALUE_MOBILE_BOTTOM_MENU_ENABLED = 'On';

export const FEATURE_NAME_EXPEDIA_POPUNDER = 'ExpediaPopunder';
export const FEATURE_VALUE_EXPEDIA_POPUNDER_VARIANT_B = 'B_variant';
export const FEATURE_VALUE_EXPEDIA_POPUNDER_VARIANT_C = 'C_variant';

export const FEATURE_NAME_CHECK_IN_LEGAL_INFORMATION = '174325_CheckInLegalInformation';
export const FEATURE_VALUE_CHECK_IN_LEGAL_INFORMATION_ENABLED = 'B_new';

export const FEATURE_NAME_HIDE_FLIGHT_SEARCH_EVERYWHERE_DESKTOP =
  '177693_HideFlightSearchEverywhereDesktop';
export const FEATURE_VALUE_HIDE_FLIGHT_SEARCH_EVERYWHERE_DESKTOP = 'On';

export const FEATURE_NAME_CLOSE_PASSENGER_DETAILS = '179669ClosePassengerDetails';
export const FEATURE_VALUE_CLOSE_PASSENGER_DETAILS_ENABLED = 'B_new';

export const FEATURE_NAME_ADD_CORPORATE_CARD_FEE = '171465_AddCorporateCardFee';
export const FEATURE_VALUE_TOGGLE_ON = 'On';

export const FEATURE_NAME_FLIGHT_DETAIL_BLOCK_REDESIGN = '156263_FlightDetailBlock';
export const CC_FEATURE_NAME_FLIGHT_DETAIL_BLOCK_REDESIGN = 'FlightDetailBlock_156263';
export const FEATURE_VALUE_FLIGHT_DETAIL_BLOCK_REDESIGN_ENABLED = 'B_new';

export const FEATURE_NAME_FARE_FINDER_PAST_PRICE = '182891_FareFinderPastPrice';
export const FEATURE_VALUE_FARE_FINDER_PAST_PRICE = 'B_new';

export const FEATURE_NAME_POPULAR_TAG = '178580_PopularTag';
export const FEATURE_VALUE_POPULAR_TAG_VARIANT_B = 'Variant_B';
export const FEATURE_VALUE_POPULAR_TAG_VARIANT_C = 'Variant_C';

export const FEATURE_NAME_FLIGHT_DETAIL_BLOCK_CI_COMPONENT_MOBILE =
  'FlightDetailBlockCIComponentMobile_186955';
export const FEATURE_VALUE_FLIGHT_DETAIL_BLOCK_CI_COMPONENT_MOBILE_ENABLED = 'B_new';

export const FEATURE_NAME_SPLIT_PAX = 'SplitPax';
export const FEATURE_VALUE_SPLIT_PAX_ENABLED = 'C_enabled';

export const FEATURE_NAME_NEW_PASSENGER_DETAILS = 'PassengerDetailsRedesign_ 179661';
export const FEATURE_VALUE_NEW_PASSENGER_DETAILS_ENABLED = 'B_new';

export const CC_FEATURE_NAME_FRONTEND_BACKEND_SYNC_TEST = 'frontendbackendsynctest';

export const defaultFeatureValueMap = new Map([
  [
    CC_FEATURE_NAME_FLIGHT_DETAIL_BLOCK_REDESIGN,
    FEATURE_VALUE_FLIGHT_DETAIL_BLOCK_REDESIGN_ENABLED,
  ],
  [CC_FEATURE_NAME_FRONTEND_BACKEND_SYNC_TEST, false],
]);

export const FEATURE_NAME_APP_LIKE_MOBILE_CARD = 'AppLikeMobileCard_173024';
export const FEATURE_VALUE_APP_LIKE_MOBILE_CARD_ENABLED = 'B_new';

export const FEATURE_NAME_PROFILE_PRICE_ALERT = '190183_ProfilePriceAlert';
export const FEATURE_VALUE_PROFILE_PRICE_ALERT_ENABLED = 'On';

export const FEATURE_NAME_SITTING_TOGETHER_WITH_30_DAYS_CI =
  '192338_SittingTogetherWith30DaysCI';
export const FEATURE_VALUE_SITTING_TOGETHER_WITH_30_DAYS_CI_ENABLED = 'B_new';

export const FEATURE_NAME_PRIVILEGE_PASS_ACTIVATION_NEW_BAG_PAGE =
  'PrivilegePassActivationNewBagPage_191740';
export const FEATURE_VALUE_PRIVILEGE_PASS_ACTIVATION_NEW_BAG_PAGE_ENABLED = 'B_new';

export const FEATURE_NAME_NEW_CHECK_IN_ALL_DONE_SEATING =
  'NewCheckInAllDoneSeating_195348';
export const FEATURE_VALUE_NEW_CHECK_IN_ALL_DONE_SEATING_ENABLED = 'B_new';

export const FEATURE_NAME_RECOMMEND_26KG_BAG = 'Recommended26KgBag_196310';
export const FEATURE_VALUE_RECOMMEND_26KG_BAG_ENABLED = 'B_new';
