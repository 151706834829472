import { isSSRBuild } from '~/utils/ssr/build-type';

export const ANCILLARY_FASTTRACK = 'ancillary/fasttrack';
export const ANCILLARY_LOUNGE = 'ancillary/lounge';
export const ASSET_ANALYTICS = 'asset/analytics';
export const ASSET_BANK_TRANSFER_FIELDS = 'asset/BankTransferFields';
export const ASSET_BREAKING_NEWS = 'asset/breakingnews';
export const ASSET_BUNDLES = 'asset/bundles';
export const ASSET_CHECK_MOBILE_BOARDING_PASS_FOR_STATION =
  'asset/checkMobileBoardingPassForStation';
export const ASSET_COUNTRY = 'asset/country';
export const ASSET_CULTURE = 'asset/culture';
export const ASSET_CULTURES = 'asset/cultures';
export const ASSET_CURRENCIES = 'asset/currencies';
export const ASSET_DISRUPTEDFLIGHTS = 'asset/disruptedflights';
export const ASSET_EMERGENCY_NEWS = 'asset/emergencyNews';
export const ASSET_FARECHART = 'asset/farechart';
export const ASSET_FEATURE_TOGGLING = 'asset/featureToggling';
export const ASSET_INIT_FEATURE_TOGGLING = 'asset/initFeatureToggling';
export const ASSET_FEEPRICE = 'asset/feeprice';
export const ASSET_FLIGHTINFORMATION = 'asset/flightinformation';
export const ASSET_IMPORTANT_INFO = 'asset/importantInfo';
export const ASSET_INSPIRATIONAL_TOOL_DETAIL = 'asset/inspirationalToolDetails';
export const ASSET_INSURANCE = 'asset/insurance';
export const ASSET_LOGGING_TOOLS = 'asset/loggingTools';
export const ASSET_MAP = 'asset/map';
export const ASSET_DISRUPTION_CREDIT_INFORMATION = 'asset/disruptionCreditInformation';
export const ASSET_PRIVILEGE_PASS = 'asset/privilegePass';
export const ASSET_SERVICE_FEES = 'asset/serviceFees';
export const ASSET_TIMEOUT = 'asset/timeout';
export const ASSET_WDC_PRICE = 'asset/wdcPrice';
export const ASSET_YELLOW_RIBBON = 'asset/yellowRibbon';
export const ASSET_CALL_CENTER_INFORMATION = 'asset/callCenterInformation';
export const BOOKING_ACCEPT_CHANGE = 'booking/acceptChange';
export const BOOKING_ACCEPT_GCC = 'booking/acceptGcc';
export const BOOKING_AIRPORTPARKING = 'booking/airportparking';
export const BOOKING_ANALYTICS = 'booking/analytics';
export const BOOKING_ANCILLARIES = 'booking/ancillaries';
export const BOOKING_ANCILLARY_PROMO_DETAILS = 'booking/ancillaryPromoDetails';
export const BOOKING_BUNDLE_UPSELL_SELECT = 'booking/BundleUpsellSelect';
export const BOOKING_CALCULATE_BUNDLE_UPSELL = 'booking/calculateBundleUpsell';
export const BOOKING_CANCELLATION = 'booking/cancellation';
export const BOOKING_CANCELLATION_FLOW = 'booking/cancellationFlow';
export const BOOKING_CAPTCHA_CHECK = 'booking/captchaCheck';
export const BOOKING_CARFRAME = 'booking/carframe';
export const BOOKING_CAR_TRAWLER_FRAME = 'booking/CarTrawlerFrame';
export const BOOKING_CHECK_IF_DOUBLE_BOOKING_EXIST = 'booking/checkIfDoubleBookingExists';
export const BOOKING_CONTACT = 'booking/contact';
export const BOOKING_COUPON = 'booking/coupon';
export const BOOKING_DETAILED_REFUNDABLE_AMOUNT = 'booking/DetailedRefundableAmount';
export const BOOKING_MODIFY = 'booking/modifyBooking';
export const BOOKING_DIVIDE_FLIGHT_CHANGE_SERVICES = 'booking/divideFlightChangeServices';
export const BOOKING_DIVIDE_STATUS = 'booking/divideStatus';
export const BOOKING_WDC_CUT_STATUS = 'booking/WdcMembershipCutStatus';
export const BOOKING_FARELOCK_FINALIZE = 'booking/farelockfinalize';
export const BOOKING_FLIGHT_CHANGE_INFORMATION = 'booking/flightChangeInformation';
export const BOOKING_GROUP_ITINERARY = 'booking/groupItinerary';
export const BOOKING_GROUP_NAME_CHANGE = 'booking/GroupNameChange';
export const BOOKING_GROUP_PASSENGER = 'booking/groupPassenger';
export const BOOKING_INFORMATION_BANNER = 'booking/informationBanner';
export const BOOKING_INSURANCE = 'booking/insurance';
export const BOOKING_INVOICE = 'booking/invoice';
export const BOOKING_INVOICE_LIST = 'booking/invoiceList';
export const BOOKING_ITINERARY = 'booking/itinerary';
export const BOOKING_ITINERARY_PAGE_PROMO_IMAGES = 'booking/BoardingCardBanners';
export const BOOKING_MOVE_INFANT = 'booking/moveInfant';
export const BOOKING_NAME_CHANGE = 'booking/nameChange';
export const BOOKING_PASSENGERS = 'booking/passengers';
export const BOOKING_PHONE_CONTACT = 'booking/phoneContact';
export const BOOKING_PREMIUM_SEAT_REFUND = 'booking/premiumSeatRefund';
export const BOOKING_PRICE_DETAILS = 'booking/priceDetails';
export const BOOKING_PRIVILEGE_PASS = 'booking/privilegePass';
export const BOOKING_REFUND = 'booking/refund';
export const BOOKING_REMOVE_TRAVEL_PARTNER = 'booking/removeTravelPartner';
export const BOOKING_SEATMAP = 'booking/seatmap';
export const BOOKING_SELECT = 'booking/select';
export const BOOKING_SEND_GROUP_REQUEST = 'booking/sendGroupRequest';
export const BOOKING_SUCCESSFUL_BOOKING = 'booking/successfulBooking';
export const BOOKING_SUMMARY = 'booking/summary';
export const BOOKING_UPLOAD_PASSENGERS_CSV = 'booking/uploadPassengersCsv';
export const BOOKING_WIZZ_DISCOUNT_CLUB = 'booking/wizzDiscountClub';
export const BOOKING_WIZZ_DISCOUNT_CLUB_RENEWAL = 'booking/wizzDiscountClubRenewal';
export const BOOKING_ANCILLARY_FLASH_PROMOS = 'booking/AncillaryFlashPromos';
export const GET_CHATBOT_DETAILS = 'chatbot/getParameters';
export const CHEAP_FLIGHTS_DATA_SOURCE = 'search/CheapFlights';
export const CHECKIN_BOARDING_CARD = 'checkin/boardingCard';
export const CHECKIN_CHECKIN = 'checkin/checkin';
export const CHECKIN_PASSENGER_VALIDATION = 'checkin/passengerValidation';
export const CHECKIN_TRAVEL_DOCUMENT = 'checkin/travelDocument';
export const CHECKIN_TRAVEL_DOCUMENT_VALIDATION = 'checkin/travelDocumentValidation';
export const CLAIM_CHECK_RECORD_LOCATOR = 'claim/checkRecordLocator';
export const CLAIM_CLAIM = 'claim/claim';
export const CLAIM_CLAIM_LANGUAGES = 'claim/claimLanguages';
export const CLAIM_FLEX_REFUND = 'claim/flexRefund';
export const CLAIM_FLIGHTCOMPENSATION = 'claim/flightcompensation';
export const CLAIM_MYCLAIMS = 'claim/MyClaims';
export const CLAIM_REFUND_COMPENSATION = 'claim/refundCompensation';
export const CLAIM_VALIDATE_CLAIMABILITY = 'claim/validateClaimability';
export const CLAIM_CLAIMDETAILS = 'claim/claimDetails';
export const CLAIM_SUPPLEMENT_CLAIM = 'claim/supplementclaim';
export const CLAIM_TRAVEL_SERVICE_REFUND_CLAIM = 'claim/TravelServiceRefund';
export const CMS_PAGE_RESOURCES = `${
  isSSRBuild ? '/cms' : ''
}/api/pageresources/list.json`;
export const CUSTOMER_AGENCY_REGISTRATION = 'customer/agencyRegistration';
export const CUSTOMER_AUTOLOGIN = 'customer/autologin';
export const CUSTOMER_CHANGEPASSWORD = 'customer/changepassword';
export const CUSTOMER_CONSENT = 'customer/consent';
export const CUSTOMER_CUSTOMERACCOUNTHISTORY = 'customer/customeraccounthistory';
export const CUSTOMER_DATAINQUIRY = 'customer/datainquiry';
export const CUSTOMER_EMAIL_VALIDATION = 'customer/emailValidation';
export const CUSTOMER_FEEDBACK = 'customer/feedback';
export const CUSTOMER_FORGOT_PASSWORD = 'customer/forgotPassword';
export const CUSTOMER_LOGIN = 'customer/login';
export const CUSTOMER_LOGOUT = 'customer/logout';
export const CUSTOMER_MYBOOKINGS = 'customer/mybookings';
export const CUSTOMER_NEWSLETTER = 'customer/newsletter';
export const CUSTOMER_PROFILE = 'customer/profile';
export const CUSTOMER_REGISTRATION = 'customer/registration';
export const CUSTOMER_STORED_CREDIT_CARD = 'customer/storedCreditCard';
export const CUSTOMER_UPDATEMOBILEPHONE = 'customer/updatemobilephone';
export const CUSTOMER_VALIDATE_FORGOT_PASSWORD = 'customer/validateForgotPassword';
export const CUSTOMER_VERIFY_CAPTCHA = 'customer/verifyCaptcha';
export const CUSTOMER_VOUCHERHISTORY = 'customer/voucherhistory';
export const CUSTOMER_YOUR_NEXT_FLIGHT = 'customer/yourNextFlight';
export const CUSTOMER_YOUR_NEXT_CHANGED_FLIGHT = 'customer/yourNextChangedFlight';
export const CUSTOMER_FREE_WDC = 'customer/wizzFreeClub';
export const CUSTOMER_SET_COOKIE_POLICY = 'customer/saveCookiePolicy';
export const CUSTOMER_GET_COOKIES_IN_POLICY = 'customer/getCookiePolicies';
export const CUSTOMER_ADD_UPCOMING_FLIGHT = 'customer/AddUpcomingFlight';
export const CUSTOMER_REMOVE_UPCOMING_FLIGHT = 'customer/DeleteUpcomingFlight';
export const CUSTOMER_EMAIL_VERIFICATION = 'customer/emailVerification';
export const CUSTOMER_EMAIL_VERIFICATION_RESEND_EMAIL =
  'customer/reSendTwoStepVerificationEmail';
export const CUSTOMER_GET_CUSTOMER_PRICE_ALERTS = 'customer/GetCustomerPriceAlerts';
export const CUSTOMER_ADD_CUSTOMER_PRICE_ALERT = 'customer/AddCustomerPriceAlert';
export const CUSTOMER_DELETE_CUSTOMER_PRICE_ALERT = 'customer/DeleteCustomerPriceAlert';
export const INFORMATION_BROWSER_SUPPORT = 'information/browserSupport';
export const PAYMENT_BANK_TRANSFER = 'payment/bankTransfer';
export const PAYMENT_CREDIT_CARD = 'payment/creditCard';
export const PAYMENT_CUSTOMER_ACCOUNT = 'payment/customerAccount';
export const PAYMENT_EMPTY = 'payment/empty';
export const PAYMENT_FARELOCK = 'payment/farelock';
export const PAYMENT_IDEAL = 'payment/ideal';
export const PAYMENT_PAY_BY_LINK = 'payment/paybylink';
export const PAYMENT_TRUSTLY = 'payment/trustly';
export const PAYMENT_PAYMENT_OPTIONS = 'payment/paymentOptions';
export const PAYMENT_STORED_CREDIT_CARD = 'payment/storedCreditCard';
export const PAYMENT_VOUCHER = 'payment/voucher';
export const PAYMENT_CORPORATE_CARD_FEE_PERCENT = 'payment/corporateCardFeePercent';
export const PAYMENT_CORPORATE_CARD_FEE_INFO = 'payment/corporateCardFeeInfo';
export const PAYMENT_STORED_CORPORATE_CARD_FEE_INFO =
  'payment/storedCorporateCardFeeInfo';
export const SEARCH_ADDITIONAL_FEES = 'search/additionalFees';
export const SEARCH_FLIGHT_DATES = 'search/flightDates';
export const SEARCH_INSPIRATIONAL_FLIGHTS = 'search/inspirationalFlights';
export const SEARCH_SEARCH = 'search/search';
export const SEARCH_TIMETABLE = 'search/timetable';
export const SEARCH_WIZZ_DISCOUNT_CLUB = 'search/wizzDiscountClub';
export const SEARCH_CONNECTED_FLIGHTS = 'search/ConnectedFlights';
export const USER_SESSION_NEW = 'userSession/new';
export const USER_SESSION_KEEP_ALIVE = 'userSession/keepAlive';
export const VOUCHER_ITINERARY = 'voucher/itinerary';
export const VOUCHER_MAIL = 'voucher/mail';
export const VOUCHER_SELECT = 'voucher/select';
export const VOUCHER_VOUCHER = 'voucher/voucher';

// Check in
export const CHECK_IN_START = 'checkin/checkinFlow';
export const CHECK_IN_WARNING_STEP = 'checkin/warning';
export const CHECK_IN_PASSENGERS_STEP = 'checkin/passengers';
export const CHECK_IN_POST_PASSENGERS = 'checkin/passengers';
export const CHECK_IN_DELETE_PASSENGERS = 'checkin/passengers';
export const CHECK_IN_CONTACT_DATA_STEP = 'checkin/contactData';
export const CHECK_IN_TRAVEL_DOCUMENTS_STEP = 'checkin/travelDocuments';
export const CHECK_IN_ALL_DONE = 'checkin/successfulCheckIn';
export const CHECK_IN_CONTACT_DATA_VALIDATION = 'checkin/contactDataValidation';
export const CHECK_IN_CONFIRM_CHECK_IN = 'checkin/confirmCheckIn';

export const CHECK_IN_CONTACT_DATA_VERIFICATION = 'checkin/confirmContactData';
export const CHECK_IN_QR_SCANNING = 'checkin/qrScanning';
export const CHECK_IN_PASSENGER_LOCATOR = 'checkin/passengerLocators';
export const CHECK_IN_EU_GREEN_PASS = 'checkin/euGreenPasses';
export const CHECK_IN_WALLET_GOOGLE = 'checkin/googlewallet';
export const CHECK_IN_CHANGES_TO_ACCEPT = 'checkin/changesToAccept';
export const CHECK_IN_TRAVEL_DOCUMENTS_SCANNING = 'checkin/travelDocumentScanning';
export const CHECK_IN_PARAMS = 'checkin/CheckinParams';

// Hotels
export const BOOKING_COM_HOTELS_SEARCH = 'hotel/search';
export const EXPEDIA_LOCATION_SEARCH_BY_IATA = 'hotel/ExpediaSearch';

export const CARS_LOCATION_SEARCH = 'car/search';
export const CARS_LOCATION_SEARCH_BY_IATA = 'car/SearchLocation';
